import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { Form, DatePicker, Layout, Button, Input, message } from 'antd';

import './VoucherForm.css';

import { ServiceSelect } from '../../Selects/ServiceSelect';

import { GET_VOUCHERS } from '../../../api/Query';

const CREATE_VOUCHER = gql`
    mutation ($date: String!, $email: String!, $uses: Int!, $service: String!, $payment_type: String!, $payment_id: String!) {
        create_voucher(voucher_input: {date: $date, email: $email, uses: $uses, service: $service, payment_type: $payment_type, payment_id: $payment_id}) {
            _id
        }
    }
`;

type Props = {
    setCreateVoucher: (createVoucher: boolean) => void
}

const { Content } = Layout;

export const VoucherForm  = ({setCreateVoucher}: Props) => {
    const [createVoucher] = useMutation(CREATE_VOUCHER);
    const [selectedService, setSelectedService] = useState('');
     
    const onSubmit = (form_data: any) => {
		createVoucher({ 
			variables: {
				date: form_data.date,
				email: form_data.email,
                uses: parseInt(form_data.uses),
                service: selectedService,
                payment_type: form_data.payment_type,
                payment_id: form_data.payment_id
			},
		})
		.then(() => {
            //message.success('Voucher Created');
            //setCreateVoucher(false);
		});
    }

    return (
        <Content className="voucher-form">
            <Form layout="vertical" onFinish={onSubmit}>
                <Form.Item name="date" label="Date" rules={[{ required: true, message: 'Please provide a date'}]}>
                    <DatePicker />
                </Form.Item>
                <Form.Item name="email" label="Client Email" rules={[{ required: true, message: 'Please provide a email'}]}>
                    <Input placeholder="Client Email"/>
                </Form.Item>
                <Form.Item name="uses" label="Voucher Uses" rules={[{ required: true, message: 'Please provide uses'}]}>
                    <Input placeholder="Voucher Uses"/>
                </Form.Item>
                <Form.Item name="payment_type" label="Payment Type" rules={[{ required: true, message: 'Please provide uses'}]}>
                    <Input placeholder="Payment Type"/>
                </Form.Item>
                <Form.Item name="payment_id" label="Payment ID" rules={[{ required: true, message: 'Please provide uses'}]}>
                    <Input placeholder="Payment ID"/>
                </Form.Item>
                <Form.Item label="Service">
                    <ServiceSelect selected_service={selectedService} changeServiceHandler={(service: any) => setSelectedService(service)} />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">Create Voucher</Button>
                </Form.Item>
            </Form>
        </Content>
    );
}
