import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

export const DaySelect = ({type, changeDayHandler}: any) => {
    let days = [];
    if(type === 'full') {
        days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    } else {
        days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    }

    return (
        <Select style={{ width: '100%'}} placeholder="Add a Day" onChange={(day: string) => changeDayHandler(day)}>
            {days && days.map((day: string, index: number) => {
                return (
                    <Option key={index} value={day}>{day}</Option>
                )
            })}
        </Select>
    );
}
