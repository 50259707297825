import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { Form, Layout, Button, Input, Space, InputNumber, TimePicker, Checkbox, message } from 'antd';

import './TemplateForm.css';

import { ServiceSelect } from '../../Selects/ServiceSelect';
import { generateTemplate } from '../../../tools/template-generator';

import { GET_TEMPLATES } from '../../../api/Query';

const CREATE_TEMPLATE = gql`
    mutation ($name: String!,  $data: String!) {
        create_template(template_input: {name: $name, data: $data}) {
            _id
        }
    }
`;

type Props = {
    setCreateTemplate: (createTemplate: boolean) => void
}

const { Content } = Layout;
const { RangePicker } = TimePicker;

export const TemplateForm  = ({setCreateTemplate}: Props) => {
    const [selectedService, setSelectedService] = useState('');
    const [staffColumn, setStaffColumn] = useState(false);
    const [createTemplate] = useMutation(CREATE_TEMPLATE);

    const onSubmit = (form_data: any) => {
        const template = generateTemplate(form_data, selectedService, staffColumn);
		createTemplate({ 
			variables: {
				name: form_data.template_name,
                data: JSON.stringify(template),
			},
			refetchQueries: [
				{ query: GET_TEMPLATES },
			]
		})
		.then(() => {
            message.success('Template Created');
            setCreateTemplate(false);
		});
    }

    const setServiceHandler = (service: any) => {
        setSelectedService(service);
    }

    return (
        <Content className="template-form">
            <Form layout="vertical" onFinish={onSubmit}>
                <Form.Item name="template_name" label="Template Name" rules={[{ required: true, message: 'Please provide a template name'}]}>
                    <Input placeholder="Template Name"/>
                </Form.Item>
                <Form.Item name="rooms" label="Rooms" rules={[{ required: true }]}> 
                    <InputNumber defaultValue={0} />
                </Form.Item>
                <Form.Item name="time" label="Time" rules={[{ required: true }]}> 
                    <RangePicker format="HH:mm"/>
                </Form.Item>
                <Form.Item name="default_service" label="Default Service"> 
                    <ServiceSelect changeServiceHandler={(service: any) => setServiceHandler(service)} />
                </Form.Item>
                <Form.Item name="staff_column"> 
                    <Checkbox checked={staffColumn} onChange={() => setStaffColumn(!staffColumn)}>Create Staff Column</Checkbox>
                </Form.Item>
                <Form.Item>
                    <Space>
                        <Button type="primary" style={{backgroundColor: 'red', borderColor: 'red'}} onClick={()=> setCreateTemplate(false)}>Cancel</Button>
                        <Button type="primary" htmlType="submit">Create Template</Button>
                    </Space>
                </Form.Item>
            </Form>
        </Content>
    );
}
