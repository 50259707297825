import React, { useEffect, useState, useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { AuthContext } from '../../context/AuthContext';
import { getUser } from '../../tools/manage-tokens';

export const CustomRoute = (props: any) => {
    const { loggedIn } = useContext(AuthContext);
    const [returnedRoute, setReturnedRoute] = useState(<Route/>);

    useEffect(() => {
        switch(props.condition) {
            case "loggedIn":
                return setReturnedRoute(
                    isEmpty(getUser()) ? <Redirect to="/login" /> : <Route {...props} /> 
                );
            case "onboarding":
                return setReturnedRoute(
                    props.settings ? <Route {...props} /> : <Redirect to="/login" />
                );
            default:
                return(setReturnedRoute(<Route {...props} />));
        }
    }, [loggedIn, props]);
    return <>{returnedRoute}</>;
};
    
