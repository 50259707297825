import React, { useContext, useEffect } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { useParams, useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { Row, Col, Checkbox, Form, message, Button, Input} from 'antd';

import { GET_BOOKING, CREATE_CLIENT_FORM } from '../../../api/Query';

import { AppContext } from '../../../context/AppContext';

import './ClientForm.css';

const GET_SLOT = gql`
    query GetSlot($slot_id: ID!){
        slot (slot_id: $slot_id) {
            _id
            time
            service_id {
                _id
            }
            staff_id {
                _id
            }
        }
    }
`;

const GET_STAFF_MEMBER = gql`
    query ($staff_id: ID!){
        staff_member (staff_id: $staff_id) {
            _id
            first_name
            last_name
        }
    }
`;

const { TextArea } = Input;

export const ClientForm = () => {
    let { slot_id } = useParams<{ slot_id: string }>();
    const { setSidebarVisible } = useContext(AppContext);
    const [createClientForm] = useMutation(CREATE_CLIENT_FORM);
    let history = useHistory();

    useEffect(() => {
        setSidebarVisible(false);
    }, [setSidebarVisible]);

    const { data: bookingData } = useQuery(GET_BOOKING, {
        variables: {slot_id: slot_id}
    });

    const { data: slotData } = useQuery(GET_SLOT, {
        variables: {slot_id: slot_id}
    });

    const staff_id = slotData?.slot?.staff_id._id;

    const { data: staffData } = useQuery(GET_STAFF_MEMBER, {
        variables: {staff_id: staff_id},
        skip: !staff_id
    });

	const onSubmit = (form_data: any) => {
        createClientForm({ 
			variables: {
				client_name: `${form_data.first_name}-${form_data.last_name}`,
                staff_name: `${staffData.staff_member.first_name}-${staffData.staff_member.last_name}`, 
                date: format(new Date(), 'yyyy-MM-dd'),
                time: slotData.slot.time,
				form_data: JSON.stringify(form_data),
                booking_id: bookingData.booking._id
			}
		})
		.then(() => {
            setSidebarVisible(true);
			message.success('Client Form Saved');
            history.push('/dashboard/staff-bookings');
        });
	}

    return (
        <div className="client-form-container">
            {bookingData && (
                <Form layout="vertical" onFinish={onSubmit} initialValues={bookingData.booking}>
                    <Row gutter={12}>
                        <Col span={12}>
                            <Form.Item name="first_name" label="First Name"> 
                                <Input placeholder="First Name"/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="last_name" label="Last Name"> 
                                <Input placeholder="Last Name"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={12}>
                        <Col span={12}>
                            <Form.Item name="mobile" label="Mobile"> 
                                <Input placeholder="Mobile"/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="email" label="Email"> 
                                <Input placeholder="Email"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={12}>
                        <Col span={12}>
                            <Form.Item name="gender" label="Gender"> 
                                <Input placeholder="Gender"/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="dob" label="Date Of Birth"> 
                                <Input placeholder="D.O.B"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <h4>Areas Of Massage</h4>
                    <Row>
                        <Col span={12} style={{display: 'flex', flexDirection: 'row'}}>
                            <Form.Item name="area-upper" valuePropName="checked" noStyle>
                                <Checkbox>Upper Body</Checkbox>
                            </Form.Item>
                            <Form.Item name="area-lower" valuePropName="checked" noStyle>
                                <Checkbox>Lower Body</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <br />
                    <h4>Areas Of Soreness</h4>
                    <Row>
                        <Col span={12} style={{display: 'flex', flexDirection: 'row'}}>
                            <Form.Item name="sore-neck" valuePropName="checked" noStyle>
                                <Checkbox>Neck</Checkbox>
                            </Form.Item>
                            <Form.Item name="sore-shoulders" valuePropName="checked" noStyle>
                                <Checkbox>Shoulders</Checkbox>
                            </Form.Item>
                            <Form.Item name="sore-back" valuePropName="checked" noStyle>
                                <Checkbox>Upper Back</Checkbox>
                            </Form.Item>
                            <Form.Item name="sore-hips" valuePropName="checked" noStyle>
                                <Checkbox>Hips</Checkbox>
                            </Form.Item>
                            <Form.Item name="sore-legs" valuePropName="checked" noStyle>
                                <Checkbox>Legs</Checkbox>
                            </Form.Item>
                            <Form.Item name="sore-feet" valuePropName="checked" noStyle>
                                <Checkbox>Feet</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <br />
                    <h4>Blood Pressure</h4>
                    <Row>
                        <Col span={12} style={{display: 'flex', flexDirection: 'row'}}>
                            <Form.Item name="bp-high" valuePropName="checked" noStyle>
                                <Checkbox>High</Checkbox>
                            </Form.Item>
                            <Form.Item name="bp-normal" valuePropName="checked" noStyle>
                                <Checkbox>Normal</Checkbox>
                            </Form.Item>
                            <Form.Item name="bp-low" valuePropName="checked" noStyle>
                                <Checkbox>Low</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <br />
                    <h4>Are you taking medications?</h4>
                    <Row>
                        <Col span={12} style={{display: 'flex', flexDirection: 'row'}}>
                            <Form.Item name="medication-yes" valuePropName="checked" noStyle>
                                <Checkbox>Yes</Checkbox>
                            </Form.Item>
                            <Form.Item name="medication-no" valuePropName="checked" noStyle>
                                <Checkbox>No</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <br />
                    <h4>Have you had any surgeries or injuries in the last 3 month?</h4>
                    <Row>
                        <Col span={12} style={{display: 'flex', flexDirection: 'row'}}>
                            <Form.Item name="injuries-yes" valuePropName="checked" noStyle>
                                <Checkbox>Yes</Checkbox>
                            </Form.Item>
                            <Form.Item name="injuries-no" valuePropName="checked" noStyle>
                                <Checkbox>No</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <br />
                    <h4>Are you in good health?</h4>
                    <Row>
                        <Col span={12} style={{display: 'flex', flexDirection: 'row'}}>
                            <Form.Item name="good-health-yes" valuePropName="checked" noStyle>
                                <Checkbox>Yes</Checkbox>
                            </Form.Item>
                            <Form.Item name="good-health-no" valuePropName="checked" noStyle>
                                <Checkbox>No</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <br />
                        <Form.Item name="reason" label="Reason"> 
                            <TextArea rows={4} />
                        </Form.Item>
                    <br />
                    <br />
                    <Button type="primary" htmlType="submit" block>
                        Save
                    </Button>
                </Form>
            )}
        </div>
    );
}
