import React, { useState, useContext, useEffect } from 'react';
import { useMutation, gql } from '@apollo/client';
import { Checkbox, Layout, Form, Input, Button } from 'antd';
import { saveUser } from '../../tools/manage-tokens';
import { useHistory } from 'react-router-dom';
import { UserOutlined, LockOutlined, ReadOutlined } from '@ant-design/icons';

import './LoginPage.css';

import { AuthContext } from '../../context/AuthContext';
import { AppContext } from '../../context/AppContext';

const { Content } = Layout;

export const LoginPage = () => {
    const { setLoggedIn, setRole } = useContext(AuthContext);
    const { setSidebarVisible } = useContext(AppContext);
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState(false);
    let history = useHistory();

    useEffect(() => {
        setSidebarVisible(false);
    });

    const [login, { error: loginError }] = useMutation(gql`
        mutation Login($email: String!, $password: String!) {
            login(email: $email, password: $password){
                _id
                role
            }
        }
    `);

    if(loginError) {
        setError(true);
    }
    
    const onFinish = (data: any) => {
        setProcessing(true);
		login({ 
			variables: {
				email: data.username,
				password: data.password 
			},
		})
        .then((res) => {
            setProcessing(false);
            if(res.data.login === null) {
                setError(true);
            } else {
                const role = res.data.login.role;
                setRole(role);
                setLoggedIn(true);
                saveUser(res.data.login);
                if(role === 'admin') {
                    history.push('/');
                    window.location.reload();
                }
                if(role === 'staff') {
                    history.push('/dashboard/staff-bookings');
                }
            }
        })
    }

    const onFinishFailed = () => {
        console.log('form failed');
    }

    return (
        <Content style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <Form name="login_form" layout="vertical" style={{ width: '600px', padding: '4rem', backgroundColor: 'white', textAlign: 'center' }} initialValues={{ remember: true }} onFinish={onFinish} onFinishFailed={onFinishFailed}>
                <ReadOutlined style={{ fontSize: '8rem' }}/>
                <h1 style={{ fontSize: '2rem' }}>Journal Login</h1>
                {error && (
                    <h4 style={{ color: 'red' }}>Invalid Username/Password</h4>
                )}
                <Form.Item label="Username" name="username" rules={[{ required: true, message: 'Please input your username!' }]}>
                    <Input prefix={<UserOutlined className="site-form-item-icon" />} size="large" style={{ borderRadius: '1rem' }} placeholder="Username" />
                </Form.Item>
                <Form.Item label="Password" name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
                    <Input prefix={<LockOutlined className="site-form-item-icon" />} type="password" size="large" style={{ borderRadius: '1rem' }} placeholder="Password" />
                </Form.Item>
                <Form.Item>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox>Remember me</Checkbox>
                </Form.Item>
                    <a className="login-form-forgot" href="/">Forgot password</a>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" size="large" shape="round" disabled={processing} block htmlType="submit" className="login-form-button">
                        Log in
                    </Button>
                </Form.Item>
            </Form>
        </Content>
    )
}

