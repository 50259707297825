import React from 'react';
import { useQuery } from '@apollo/client';

import { GET_BOOKING } from '../../api/Query';

export const BookingString = ({slot_id}: any) => {

    const { data: bookingData } = useQuery(GET_BOOKING, {
		variables: {slot_id: slot_id},
        skip: !slot_id
    });

    return (
        <>
            {bookingData && bookingData.booking && (
                <h3>{bookingData.booking.first_name} {bookingData.booking.last_name}</h3>
            )}
        </>
    );
}
