import React from 'react';
import { Tabs, Layout } from 'antd';

import './DashboardPage.css';

import { StaffBookingsContainer } from '../../containers/StaffBookingsContainer/StaffBookingsContainer';

const { TabPane } = Tabs;
const { Content } = Layout;

export const DashboardPage = () => {
    return (
        <Content className="settings-page">
            <Tabs defaultActiveKey="1">
                <TabPane tab="Staff Bookings" key="1">
                    <StaffBookingsContainer />
                </TabPane>
            </Tabs>
        </Content>
    );
}
