import React from 'react';
import { UserOutlined } from '@ant-design/icons';
import { useQuery, gql } from '@apollo/client';

const GET_BOOKING = gql`
	query ($slot_id: ID!){
		booking(slot_id: $slot_id) {
			_id
            first_name
            last_name
		}
	}
`;

export const BookingSelect = ({available, slotId}: any) => {
    const { data: bookingData } = useQuery(GET_BOOKING, {
		variables: {slot_id: slotId},
        skip: available
    });

    return (
        <>
            {available ? (
                <h4><UserOutlined/> -</h4>
            ) : (
                <>
                    {bookingData && 
                        <h4><UserOutlined/> - {bookingData.booking.first_name} {bookingData.booking.last_name}</h4>
                    }
                </>
            )}
        </>
    );
}
