import React, { useContext } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Modal, message, Card, Row, Col, Button } from 'antd';
import { convertFormattedMin } from '../../../tools/time-converter';

import './CreateCalendarModal.css';

import { AppContext } from '../../../context/AppContext';

import { GET_TEMPLATES, GET_CALENDAR } from '../../../api/Query';

const CREATE_FROM_TEMPLATE = gql`
    mutation ($template_id: ID!, $date: String!){
        create_from_template(template_id: $template_id, date: $date) {
			_id
		}
	}
`;

export const CreateCalendarModal = ({isOpen, toggle}: any) => {
	const [createFromTemplate] = useMutation(CREATE_FROM_TEMPLATE); 
    const { selectedDate } = useContext(AppContext);
	const { data: templateData } = useQuery(GET_TEMPLATES); 

    const onSubmit = (templateId: string) => {
		createFromTemplate({ 
			variables: {
				template_id: templateId,
                date: selectedDate
			},
			refetchQueries: [
                { query: GET_CALENDAR, variables: { date: selectedDate} }
			]
		})
		.then(() => {
			toggle();
            message.success('Calendar Created');
		});
    }

    return (        
        <Modal title="Create Calendar" visible={isOpen} onCancel={toggle} footer={null}>
            {templateData && templateData.templates.map((template: any, index: number) => {
                return (
                    <Row key={index} style={{ marginBottom: '1rem' }}>
                        <Col span={24}>
                            <Card title={template.name} extra={<Button type="primary" onClick={() => onSubmit(template._id)}>Create</Button>} style={{ width: '100%' }}>
                                <p>Rooms: {JSON.parse(template.description).rooms}</p>
                                <p>Start Time: {convertFormattedMin(JSON.parse(template.description).start_time)}</p>
                                <p>End Time: {convertFormattedMin(JSON.parse(template.description).end_time)}</p>
                            </Card>
                        </Col>
                    </Row>
                )
            })}
        </Modal>
    )
}
