import React from 'react';
import { Select } from 'antd';

type Props = {
    selected_pay_gateway?: string,
    changePayGatewayHandler: (payment_gateway: string) => void,
};

const { Option } = Select;

export const PayGatewaySelect = ({selected_pay_gateway, changePayGatewayHandler}: Props) => {

    return (
        <Select style={{ width: '100%' }} value={selected_pay_gateway} onChange={(payment_gateway: any) => changePayGatewayHandler(payment_gateway)}>
            <Option key="no-payment" value="no-payment">No Payment</Option>
            <Option key="square" value="square">Square</Option>
            <Option key="stripe" value="stripe">Stripe</Option>
        </Select>
    );
}
