import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { ConfigProvider, Empty, List, Radio } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import { format, parseISO } from 'date-fns';

import { BookingContext } from '../../../context/BookingContext';

import { GET_NEXT_CALENDARS } from '../../../api/Query';

export const SlotDates = () => {
    const { calendarId, setCalendarId, setDate } = useContext(BookingContext);

    const { data: nextCalendarsData } = useQuery(GET_NEXT_CALENDARS);

    const changeCalendarHandler = (calendar_item: any) => {
        const date = new Date(calendar_item.date);
        setCalendarId(calendar_item._id);
        setDate(date);
    }

    return (
        <>
            {nextCalendarsData && (
                <>
                    <h4><b><CalendarOutlined /> Available Dates</b></h4>
                    <ConfigProvider renderEmpty={() => <Empty description="No Appointments Available"/>}>
                        <List dataSource={nextCalendarsData.next_calendars} renderItem={(calendar_item: any, index: number) => (
                            <Radio.Group style={{ display: 'block' }} onChange={() => changeCalendarHandler(calendar_item)} value={calendarId}>
                                <List.Item className="booking-dates-item">
                                    <Radio value={calendar_item._id}>
                                        {format(parseISO(calendar_item.date), 'cccc do MMMM')}
                                    </Radio>
                              </List.Item>
                            </Radio.Group>
                        )}/>
                    </ConfigProvider>
                </>
            )}
        </>
    );

}
