import React, { useContext } from 'react';
import { ConfigProvider, Empty, List, Radio } from 'antd';
import { HomeOutlined } from '@ant-design/icons';

import { BookingContext } from '../../../context/BookingContext';

export const Locations = () => {
    const { locationId, setLocationId } = useContext(BookingContext);

    const locations = [
        'Jindabyne',
        'Lake Crackenback Resort',
        'Thredbo Village',
        'Perisher Valley',
        'Smiggin Holes',
        'Tyrolean Village / East Jindabyne',
        'Thredbo Valley (Tinkersfield, Pender Lea)',
        'Lakewood Estate / Moonbah',
    ];

    const changeLocationHandler = (e: any) => {
        const location_id = e.target.value;
        setLocationId(location_id);
    }

    return (
        <>
            <h4><b><HomeOutlined /> Please Select A Location</b></h4>
            {locations && 
                <ConfigProvider renderEmpty={() => <Empty description="No Appointments Available"/>}>
                    <List dataSource={locations} renderItem={(location_item: any)=> (
                        <Radio.Group style={{ display: 'block' }} onChange={changeLocationHandler} value={locationId}>
                            <List.Item>
                                <Radio value={location_item}>
                                    {location_item}
                                </Radio>
                            </List.Item>
                        </Radio.Group>
                    )}/>
                </ConfigProvider>
            }
        </>
    );
}
