import React, { useContext, forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import { Button } from 'antd';

import './Datepicker.css';
import "react-datepicker/dist/react-datepicker.css";

import { AppContext } from '../../context/AppContext'

const CustomBtn = forwardRef(({ onClick }: any, _ref: any) => (
    <Button color="primary" type="primary" onClick={onClick} ref={_ref}>
        Select Date
    </Button>
));

export const Datepicker = () => {
	const { selectedDate, setSelectedDate, setSelectedDateString } = useContext(AppContext);
    const ref = React.createRef()

    const setStartDate = (date: any) => {
        setSelectedDate(date);
        setSelectedDateString(date.toString());
        sessionStorage.setItem('selectedDate', JSON.stringify(date));
    }


	return (
		<div className="datepicker">
			<DatePicker
				selected={selectedDate}
                onChange={date => date && setStartDate(date)}
                customInput={<CustomBtn _ref={ref}/>}
				dateFormat="yyyy-MM-dd"
                popperPlacement="left-start"
                popperModifiers={{
                    preventOverflow: {
                      enabled: true,
                    },
                }}
			/>
		</div>
	);
}

