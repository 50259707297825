import React from 'react';
import { useMutation, gql } from '@apollo/client';
import { Form, Layout, Button, Input, message } from 'antd';

import './UserForm.css';

import { GET_USERS } from '../../../api/Query';

export const CREATE_USER = gql` 
    mutation CreateUser($email: String!, $password: String!, $role: String!){
        create_user(user_input: {email: $email, password: $password, role: $role}) {
			_id
		}
	}
`;

type Props = {
    setCreateUser: (createUser: boolean) => void
}

const { Content } = Layout;

export const UserForm  = ({setCreateUser}: Props) => {
    const [createUser] = useMutation(CREATE_USER);

     
    const onSubmit = (form_data: any) => {
		createUser({ 
			variables: {
				email: form_data.email,
				password: form_data.password,
                role: form_data.role,
			},
			refetchQueries: [
				{ query: GET_USERS },
			]
		})
		.then(() => {
            message.success('User Created');
            setCreateUser(false);
		});
    }

    return (
        <Content className="user-form">
            <Form layout="vertical" onFinish={onSubmit}>
                <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Please provide a email'}]}>
                    <Input placeholder="Email"/>
                </Form.Item>
                <Form.Item name="password" label="Password" rules={[{ required: true, message: 'Please provide a password'}]}>
                    <Input.Password placeholder="Password"/>
                </Form.Item>
                <Form.Item name="role" label="Role" rules={[{ required: true, message: 'Please provide a role'}]}>
                    <Input placeholder="Role"/>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">Create User</Button>
                </Form.Item>
            </Form>
        </Content>
    );
}
