import React from 'react';
import { useQuery } from '@apollo/client';
import { format } from 'date-fns';

import { GET_ROSTERED_STAFF } from '../../api/Query';

export const CalStaffString = ({date}: any) => {
    const day = format(date, 'EE');

    const { data: staffData } = useQuery(GET_ROSTERED_STAFF, {
        variables: {
            day: day
        },
        skip: !day
    });

    return (
        <>
            {staffData && staffData.rostered_staff ? (
                <h4>{day} - <b>{staffData.rostered_staff.first_name} {staffData.rostered_staff.last_name[0]}</b></h4>
            ) : (
                <h4>{day}</h4>
            )}
        </>
    );
}
