import { gql } from '@apollo/client';

export const GET_CALENDAR = gql`
	query Calendar($date: String!){
		calendar(date: $date) {
			_id
            rows {
                _id
                time
            }
		}
	}
`;


export const GET_BOOKING_DATA = gql`
    query ($calendar_id: String!){
        booking_data(calendar_id: $calendar_id)
    }
`;

export const GET_ROW = gql`
    query ($row_id: ID!){
        row(row_id: $row_id) {
            _id
            time
            slots {
                _id
                calendar_id
                service: service_id {
                    _id
                }
                time
                staff: staff_id {
                    _id
                }
                available
            }
        }
    }
`;

export const GET_SETTINGS = gql`
	query {
		settings {
			_id
            org_code
		    org_name	
            org_logo
            org_map
            stripe_enabled
            square_enabled
            sms_enabled
            mailchimp_enabled
            dropbox_enabled
            email_enabled
            admin_email
            booking_email
            contact_number
            payment_gateway
            booking_type
            production
		}
	}
`;

export const GET_CALENDAR_SETTINGS = gql`
	query {
		calendar_settings {
			_id
            bookings_enabled
            start_time
		    finish_time	
            block_out_before
            block_out_after
            client_blockout
            confirmation_body
            thanks_body
		}
	}
`;

export const GET_BLOCKOUT_SETTINGS = gql`
	query {
		blockout_settings {
			_id
            days
		}
	}
`;


export const GET_SQUARE_SETTINGS = gql`
	query {
		square_settings {
			_id
            app_id
            location_id
            access_token
            production
		}
	}
`;

export const GET_STRIPE_SETTINGS = gql`
	query {
		stripe_settings {
			_id
            secret
            publishable
		}
	}
`;

export const GET_SMS_SETTINGS = gql`
	query {
		sms_settings {
			_id
            account_sid
            access_token
            phone_number
            quota
		}
	}
`;

export const GET_SMS_USAGE = gql`
	query {
		sms_usage
	}
`;

export const GET_STRIPE_SETTINGS_SAFE = gql`
	query {
		stripe_settings {
			_id
            publishable
		}
	}
`;

export const GET_MAILCHIMP_SETTINGS = gql`
	query {
		mailchimp_settings {
			_id
            access_token
            list_id
            server
		}
	}
`;

export const GET_DROPBOX_SETTINGS = gql`
	query {
		dropbox_settings {
			_id
            path
            token
		}
	}
`;

export const GET_EMAIL_SETTINGS = gql`
    query {
		email_settings {
			_id
            from_address
		}
	}
`;

export const CREATE_SETTINGS = gql`
	mutation CreateSettings($org_code: String!, $org_name: String!, $navbar_color: String!, $admin_email: String!, $booking_email: String!, $contact_number: String!){
		create_settings(settings_input: {org_code: $org_code, org_name: $org_name, navbar_color: $navbar_color, admin_email: $admin_email, booking_email: $booking_email, contact_number: $contact_number}) {
            _id
		}
	}
`;

export const UPDATE_SETTINGS = gql`
	mutation UpdateSettings($settings_id: ID!, $org_name: String!, $navbar_color: String!, $admin_email: String!, $booking_email: String!, $contact_number: String!){
		update_settings(settings_id: $settings_id, settings_input: {org_name: $org_name, navbar_color: $navbar_color, admin_email: $admin_email, booking_email: $booking_email, contact_number: $contact_number}) {
            _id
		}
	}
`;

export const GET_SLOTS = gql`
    query Slots($column_id: ID!){
        slots(column_id: $column_id) {
			_id
            calendar_id
			column_id
            service: service_id {
                _id
            }
			time
            staff_id
			available
		}
	}
`;

export const GET_SLOTS_BY_STAFF = gql`
    query SlotsByStaff($staff_id: ID!){
        slots_by_staff(staff_id: $staff_id) {
			_id
            calendar_id
			column_id
            service_id
			time
            staff_id
			available
		}
	}
`;


export const GET_SLOT = gql`
    query Slot($slot_id: ID!){
        slot(slot_id: $slot_id) {
			_id
            service_id {
                _id
            }
		}
	}
`;

export const GET_NEXT_CALENDARS = gql`
	query {
		next_calendars {
			_id	
            name
            date
            rows {
                _id
                time
            }
		}
	}
`;
export const CREATE_SLOT = gql` 
	mutation CreateSlot($calendar_id: ID!, $column_id: ID!, $type: String!, $time: String!, $price: Int!){
		create_slot(slot_input: {calendar_id: $calendar_id, column_id: $column_id, type: $type, time: $time, price: $price}) {
            _id
		}
	}
`;

export const CREATE_SLOTS = gql` 
	mutation CreateSlots($calendar_id: ID!, $column_id: ID!, $service_id: ID!, $start_time: String!, $end_time: String!){
		create_slots(calendar_id: $calendar_id, column_id: $column_id, service_id: $service_id, start_time: $start_time, end_time: $end_time) {
            _id
		}
	}
`;

export const CHANGE_SLOT_TYPE = gql`
    mutation ChangeSlotType($slot_id: ID!, $type: String!){
        change_slot_type(slot_id: $slot_id, type: $type){
            _id
        }
    }
`;

export const CHANGE_SLOT_SERVICE = gql`
    mutation ChangeSlotService($slot_id: ID!, $service_id: ID!){
        change_slot_service(slot_id: $slot_id, service_id: $service_id){
            _id
        }
    }
`;

export const CHANGE_SLOT_PRICE = gql`
    mutation ChangeSlotPrice($slot_id: ID!, $price: Int!){
        change_slot_price(slot_id: $slot_id, price: $price){
            _id
        }
    }
`;

export const CHANGE_SLOT_STAFF = gql`
    mutation ChangeSlotStaff($slot_id: ID!, $staff_id: ID!){
        change_slot_staff(slot_id: $slot_id, staff_id: $staff_id){
            _id
        }
    }
`;

export const REMOVE_SLOT_STAFF = gql`
    mutation RemoveSlotStaff($slot_id: ID!, $staff_id: ID!){
        remove_slot_staff(slot_id: $slot_id, staff_id: $staff_id){
            _id
        }
    }
`;

export const DELETE_SLOT = gql`
	mutation DeleteSlot($slot_id: ID!){
		delete_slot(_id: $slot_id) {
			_id
		}
	}
`;

export const GET_BOOKING = gql` 
	query Booking($slot_id: ID!){
		booking(slot_id: $slot_id) {
			_id
			first_name
			last_name
			email
			mobile
			approved
		}
	}
`;

export const GET_BOOKINGS = gql` 
	query Bookings($date: String!){
		bookings(date: $date) {
			_id
            slot_id
			first_name
			last_name
			email
			mobile
			approved
            client_form_completed
            feedback_form_completed
		}
	}
`;

export const GETS_BOOKINGS_BY_STAFF = gql` 
	query BookingsByStaff($staff_id: ID!){
		bookings_by_staff(staff_id: $staff_id) {
			_id
            slot_id
			first_name
			last_name
			email
			mobile
			approved
            client_form_completed
            feedback_form_completed
		}
	}
`;

export const GET_BOOKING_STATS = gql`
    query {
        booking_stats
    }
`;

export const CREATE_BOOKING = gql`
	mutation CreateBooking($slot_id: ID!, $date: String!, $first_name: String!, $last_name: String!, $email: String!, $mobile: Int!){
		create_booking(booking_input: {slot_id: $slot_id, date: $date, first_name: $first_name, last_name: $last_name, email: $email, mobile: $mobile}) {
			_id
		}
	}
`;

export const DELETE_BOOKING = gql`
	mutation DeleteBooking($slot_id: ID!){
		delete_booking(slot_id: $slot_id) {
			_id
		}
	}
`;

export const GET_CALENDARS = gql`
	query Calendars($date: String!){
		calendars(date: $date) {
			_id
			name
			date
			active
		}
	}
`;

export const GET_FUTURE_CALENDARS = gql`
	query Future_Calendars($dates: [String!]) {
		future_calendars(dates: $dates) {
			date	
		}
	}
`;

export const GET_NEXT_CALENDAR = gql`
	query NextCalendar {
		next_calendar {
			_id	
            date
		}
	}
`;

export const GET_CALENDAR_TIMES = gql`
	query CalendarTimes($column_id: ID!) {
		calendar_times(column_id: $column_id) {
            _id
            service_id
            time
		}
	}
`;


export const CREATE_CALENDAR = gql`
	mutation CreateCalendar($name: String!, $date: String!, $rooms: Int!){
		create_calendar(calendar_input: {name: $name, date: $date, rooms: $rooms}) {
			_id
			date
		}
	}
`;

export const CREATE_CLIENT_FORM = gql`
    mutation CreateClientForm($client_name: String!, $staff_name: String!, $date: String!, $time: String!, $form_data: String!, $booking_id: ID!){
        create_client_form(client_form_input: {client_name: $client_name, staff_name: $staff_name, date: $date, time: $time, form_data: $form_data, booking_id: $booking_id}) {
			_id
		}
	}
`;

export const CREATE_FEEDBACK_FORM = gql`
    mutation CreateFeedbackForm($student_name: String!, $date: String!, $time: String!, $form_data: String!, $booking_id: ID!){
        create_feedback_form(feedback_form_input: {student_name: $student_name, date: $date, time: $time, form_data: $form_data, booking_id: $booking_id}) {
			_id
		}
	}
`;
export const RENAME_CALENDAR = gql`
	mutation RenameCalendar($calendar_id: ID!, $calendar_name: String!){
		rename_calendar(calendar_name_input: {calendar_id: $calendar_id, calendar_name: $calendar_name}) {
			_id
			name
		}
	}
`;

export const DELETE_CALENDAR = gql`
	mutation DeleteCalendar($calendar_id: ID!){
		delete_calendar(calendar_id: $calendar_id) {
			_id
		}
	}
`;

export const GET_COLUMNS = gql`
	query Columns($calendar_id: ID!){
		columns(calendar_id: $calendar_id) {
			_id
            column_type
			title
			index
            color
		}
	}
`;

export const GET_COLUMN = gql`
	query Column($column_id: ID!){
		column(column_id: $column_id) {
			_id
            column_type
			title
			index
            color
            slots {
                _id
                calendar_id
                column_id
                service: service_id {
                    _id
                }
                time
                staff_id
                available
            }
		}
	}
`;

export const CREATE_COLUMN = gql`
    mutation CreateColumn($calendar_id: ID!, $title: String!, $type: String!, $color: String!){
        create_column(column_input: {calendar_id: $calendar_id, title: $title, type: $type, color: $color}) {
			_id
		}
	}
`;

export const UPDATE_COLUMN_TITLE = gql`
	mutation UpdateColumnTitle($column_id: ID!, $column_title: String!){
		update_column_title(_id: $column_id, title: $column_title) {
			_id
		}
	}
`;

export const CHANGE_COLUMN_COLOR = gql`
	mutation ChangeColumnColor($column_id: ID!, $color: String!){
		update_column_title(_id: $column_id, color: $color) {
			_id
		}
	}
`;

export const DELETE_COLUMN = gql` 
	mutation DeleteColumn($column_id: ID!){
		delete_column(_id: $column_id) {
			_id
		}
	}
`;

export const GET_TEMPLATES = gql`
    query Templates {
		templates {
            _id
            name	
            description
		}
	}
`;

export const GET_TEMPLATE = gql`
    query Template($name: String!) {
		template(name: $name){
            data	
		}
	}
`;

export const CREATE_TEMPLATE = gql`
    mutation CreateTemplate($name: String!, $data: String!){
        create_template(template_input: {name: $name, data: $data}) {
			_id
		}
	}
`;

export const CREATE_FROM_TEMPLATE = gql`
    mutation CreateFromTemplate($template_id: ID!, $date: String!){
        create_from_template(template_id: $template_id, date: $date) {
			_id
		}
	}
`;

export const DELETE_TEMPLATE = gql`
    mutation DeleteTemplate($template_id: ID!){
        delete_template(template_id: $template_id) {
            _id 
        }
    }
`;

export const GET_ALL_STAFF = gql`
    query AllStaff {
        all_staff {
            _id
            first_name
            last_name
            email
            roster
            services {
                _id
                name
            }
        }
    }
`;

export const GET_STAFF = gql`
    query Staff($staff_id: ID!){
        staff_member(staff_id: $staff_id) {
            _id
            first_name
            last_name
            roster
        }
    }
`;

export const GET_ROSTERED_STAFF = gql`
    query ($day: String!){
        rostered_staff(day: $day) {
            _id
            first_name
            last_name
        }
    }
`;


export const GET_ALL_STAFF_SLOTS = gql`
    query AllStaff{
        all_staff {
            _id
            first_name
            last_name
            slots {
                time
            }
        }
    }
`;

export const GET_STAFF_SLOTS = gql`
    query Staff($staff_id: ID!){
        staff(staff_id: $staff_id) {
            _id
            first_name
            last_name
            slots {
                _id
                time
            }
        }
    }
`;

export const CREATE_STAFF = gql`
    mutation CreateStaff($first_name: String!, $last_name: String!, $email: String){
        create_staff(staff_input: {first_name: $first_name, last_name: $last_name, email: $email}) {
            _id
        }
    }
`;

export const DELETE_STAFF = gql`
    mutation DeleteStaff($staff_id: ID!){
        delete_staff(staff_id: $staff_id) {
            _id 
        }
    }
`;

export const GET_SERVICES = gql`
    query Services {
		services {
            _id
            name	
            price
            duration
		}
	}
`;

export const GET_VOUCHERS = gql`
    query {
		vouchers {
            _id
            number
            date
            email
            valid
            uses
            remaining_uses
            service {
                name
            }
		}
	}
`;

export const GET_SERVICE = gql`
    query Service($service_id: ID!){
        service(service_id: $service_id) {
            _id
            name
            price
            duration
        }
    }
`;

export const CREATE_SERVICE = gql`
    mutation CreateService($name: String!, $price: Int!, $duration: String!, $color: String!) {
        create_service(service_input: {name: $name, price: $price, duration: $duration, color: $color}) {
            _id
        }
    }
`;

export const UPDATE_SERVICE = gql`
	mutation UpdateService($service_id: ID!, $name: String!, $price: Int!, $duration: String!, $color: String!){
		update_service(service_id: $service_id, service_input: {name: $name, price: $price, duration: $duration, color: $color}) {
            _id
		}
	}
`;

export const DELETE_SERVICE = gql`
    mutation DeleteService($service_id: ID!){
        delete_service(service_id: $service_id) {
            _id 
        }
    }
`;

export const GET_PRICES = gql`
    query Prices {
		prices {
            _id
            name	
            value
		}
	}
`;

export const CREATE_PRICE = gql`
    mutation CreatePrice($name: String!, $value: Int!){
        create_price(price_input: {name: $name, value: $value}) {
            _id
        }
    }
`;

export const DELETE_PRICE = gql`
    mutation DeletePrice($price_id: ID!){
        delete_price(price_id: $price_id) {
            _id 
        }
    }
`;

export const GET_TYPES = gql`
    query Types {
		types {
            _id
            name	
            value
		}
	}
`;

export const CREATE_TYPE = gql`
    mutation CreateType($name: String!, $value: String!){
        create_type(type_input: {name: $name, value: $value}) {
            _id
        }
    }
`;

export const DELETE_TYPE = gql`
    mutation DeleteType($type_id: ID!){
        delete_type(type_id: $type_id) {
            _id 
        }
    }
`;

export const GET_DURATIONS = gql`
    query Durations {
		durations {
            _id
            name	
            value
		}
	}
`;

export const CREATE_DURATION = gql`
    mutation CreateDuration($name: String!, $value: Int!){
        create_duration(duration_input: {name: $name, value: $value}) {
            _id
        }
    }
`;

export const DELETE_DURATION = gql`
    mutation DeleteDuration($duration_id: ID!){
        delete_duration(duration_id: $duration_id) {
            _id 
        }
    }
`;

export const GET_STAFF_COLUMNS = gql`
	query StaffColumns($calendar_id: ID!){
		staff_columns(calendar_id: $calendar_id) {
			_id
			title
		}
	}
`;

export const CHANGE_STAFF_SLOT = gql`
    mutation ChangeStaffSlot($slot_id: ID!, $staff_id: ID!){
        change_staff_slot(slot_id: $slot_id, staff_id: $staff_id){
            _id
        }
    }
`;

export const LOGIN = gql`
	mutation Login($email: String!, $password: String!) {
		login(email: $email, password: $password){
            access_token
            refresh_token
            role
		}
	}
`;

export const CREATE_USER = gql` 
	mutation CreateUser($email: String!, $password: String!){
		create_user(user_input: {email: $email, password: $password}) {
			_id
			email
		}
	}
`;

export const GET_USERS = gql`
    query {
        users {
            _id
            email
        }
    }
`;
