import React, { useContext, useState } from 'react';
import { message, Form, Button, Row, Col, PageHeader, Layout } from 'antd';
import { useQuery, useMutation, gql } from '@apollo/client';

import './AdminPage.css';

import { BookingTypeSelect } from '../../components/Selects/BookingTypeSelect';

import { useAuth } from '../../hooks/useAuth';
import { AuthContext } from '../../context/AuthContext';
import { CalendarSelect } from '../../components/Selects/CalendarSelect';

import { GET_SETTINGS, } from '../../api/Query';

const UPDATE_SETTINGS = gql`
    mutation ($settings_id: ID!, $booking_type: String!){
        update_settings(settings_id: $settings_id, settings_input: {booking_type: $booking_type}) {
            _id
        }
    }
`;

const ADD_STAFF_SLOTS = gql`
    mutation ($calendar_id: String!) {
        add_staff_slots(calendar_id: $calendar_id) 
    }
`;

const { Content } = Layout;

export const AdminPage = () => {
    const { role } = useContext(AuthContext);
    const { isLoggedIn } = useAuth();
    const [addStaffSlots] = useMutation(ADD_STAFF_SLOTS);
    const [updateSettings] = useMutation(UPDATE_SETTINGS);
    const [selectedCalendar, setSelectedCalendar] = useState('');

    const { data: settingsData } = useQuery(GET_SETTINGS);

    const addColumnHandler = () => {
        addStaffSlots({ 
            variables: {
                calendar_id: selectedCalendar,
            }
        })
        .then(() => {
            message.success('Staff Column Added');
        });

    }
    const changeBookingType = (type: string) => {
        updateSettings({ 
            variables: {
                settings_id: settingsData.settings._id,
                booking_type: type 
            },
            refetchQueries: [
                { query: GET_SETTINGS },
            ]
		})
		.then(() => {
            message.success('Booking Type Updated');
		});
    }

    return (
        <Content style={{ padding: '1rem' }}>
            <PageHeader ghost={false} title='Admin Panel' />
            {isLoggedIn && (
                <>
                {role === 'admin' && (
                    <>
                        <Row gutter={[16, 16]} style={{ marginTop: '1rem' }}>
                            <Col span={12}>
                                <div className="admin-item">
                                    <h1>Booking Type</h1>
                                    {settingsData &&
                                    <Form.Item name="booking_type" label="Booking Type">
                                        <BookingTypeSelect selected_booking_type={settingsData.settings.booking_type} changeBookingTypeHandler={changeBookingType} />
                                    </Form.Item>
                                    }
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className="admin-item">
                                    <h1>Add Staff Column</h1>
                                    <Form name="basic" layout="vertical" onFinish={addColumnHandler}>
                                        <Form.Item name="calendar_id" label="Select Calendar">
                                            <CalendarSelect selected_calendar={selectedCalendar} changeCalendarHandler={setSelectedCalendar} />
                                        </Form.Item>
                                        <Button type="primary" disabled={!selectedCalendar} block shape="round" htmlType="submit">
                                            Add
                                        </Button>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </>
                )}
                </>
            )}
        </Content>
    );
}
