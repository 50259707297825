import React, { useState } from 'react';
import { Layout, PageHeader, Form, Input, Button } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import './FormPage.css';

const { Content } = Layout;

export const FormPage = () => {
    const [fields, setFields] = useState<any>([]);

    const onFinish = (values: any) => {
        console.log(values);
    };
    
    const addField = (e: any) => {
        const test = {key: 'test'};
        setFields((fields: any) => [...fields, test]);
    }

    const removeField = (e: any) => {
        console.log(e);
    }


    return (
        <Content style={{padding: '1rem'}}>
            <PageHeader ghost={false} title='Form Builder' />
            <Form name="form-builder" onFinish={onFinish}>
                {fields.map((field: any, index: number) => (
                    <Form.Item required={false} key={field.key}>
                        <Form.Item {...field} validateTrigger={['onChange', 'onBlur']} noStyle>
                            <Input placeholder="field" style={{ width: '60%' }} />
                        </Form.Item>
                        {fields.length > 0 ? (
                            <MinusCircleOutlined className="dynamic-delete-button" onClick={() => removeField(field.name)} />
                        ) : null}
                    </Form.Item>
                ))}
                <Form.Item>
                    <Button type="dashed" onClick={addField} style={{ width: '60%' }} icon={<PlusOutlined />}>
                        Add field
                    </Button>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Content>
    );
}
