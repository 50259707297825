import React from 'react';

type Props = {
    info: any
};

export const TemplateInfo = ({info}: Props) => {
    return (
        <div style={{ padding: '1rem 0' }}>
            <h4>Rooms: {info.rooms}</h4>
            <h4>Slots: {info.slots}</h4>
            <h4>Start Time: {info.start_time}</h4>
            <h4>End Time: {info.end_time}</h4>
            <h4>Staff Column: {info.staff_column}</h4>
            <h4>Default Slot Price: {info.price}</h4>
            <h4>Default Slot Duration: {info.duration}</h4>
        </div>
    );
}
