import React from 'react';

type CalendarContextType = {
    bookingId: string;
    setBookingId: (booking_id: string) => void;
    slotId: string;
    setSlotId: (slot_id: string) => void;
    rowId: string;
    setRowId: (row_id: string) => void;
    viewBookingModal: boolean;
    setViewBookingModal: (viewBookingModal: boolean) => void;
    createBookingModal: boolean;
    setCreateBookingModal: (createBookingModal: boolean) => void;
}

export const CalendarContext = React.createContext({} as CalendarContextType);
