import React, { useContext } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Menu, Layout } from 'antd';
import { HomeOutlined, CalendarOutlined, ClockCircleOutlined, DashboardOutlined, SettingOutlined, LogoutOutlined } from '@ant-design/icons';
import { NavLink, useLocation, useHistory } from 'react-router-dom';
import { deleteUser } from '../../tools/manage-tokens';

import './Sidebar.css';

import { GET_SETTINGS, } from '../../api/Query';

import { useAuth } from '../../hooks/useAuth';
import { AuthContext } from '../../context/AuthContext';
import { AppContext } from '../../context/AppContext';

const { Sider } = Layout;

export const Sidebar = () => {
    let location = useLocation();
    const { setLoggedIn, role } = useContext(AuthContext);
    const { sidebarVisible } = useContext(AppContext);
    const { isLoggedIn } = useAuth();
    let history = useHistory();
	const { data: settingsData } = useQuery(GET_SETTINGS);

	const [logout] = useMutation(gql`
        mutation {
            logout
        }
    `);

    const logoutHandler = () => {
        setLoggedIn(false);
        deleteUser();
        logout();
        history.push('/login');
    }

    return (
        <>
        {sidebarVisible &&
        <Sider breakpoint='xs' style={{height: '100vh'}}>
            <Menu defaultSelectedKeys={[location.pathname]} mode="inline" style={{height: '100vh'}}>
                {isLoggedIn ? (
                    <>
                    <Menu.Item icon={<HomeOutlined />} key="/">
                        <NavLink to="/">Home</NavLink>
                    </Menu.Item>
                    {role === 'admin' && (
                        <>
                        {settingsData && settingsData.settings.booking_type === 'slot' && (
                            <Menu.Item icon={<CalendarOutlined />} key="/slot-calendar">
                                <NavLink to="/slot-calendar">Slot Calendar</NavLink>
                            </Menu.Item>
                        )}
                        {settingsData && settingsData.settings.booking_type === 'time' && (
                            <Menu.Item icon={<ClockCircleOutlined />} key="/time-calendar">
                                <NavLink to="/time-calendar">Time Calendar</NavLink>
                            </Menu.Item>
                        )}
                        </>
                    )}
                    {settingsData && settingsData.settings.booking_type === 'slot' && (
                        <Menu.Item icon={<DashboardOutlined />} key="/dashboard">
                            <NavLink to="/dashboard">
                                    Dashboard 
                            </NavLink>
                        </Menu.Item>
                    )}
                    {role === 'admin' && (
                        <Menu.Item icon={<SettingOutlined />} key="/settings">
                            <NavLink to="/settings">Settings</NavLink>
                        </Menu.Item>
                    )}
                    <Menu.Item icon={<LogoutOutlined />} key="/logout" onClick={logoutHandler}>
                        Logout
                    </Menu.Item>
                    </>
                ) : (
                    <Menu.Item icon={<LogoutOutlined />} key="/login">
                        <NavLink to="/login">Login</NavLink>
                    </Menu.Item>
                )}
            </Menu>
        </Sider>
        }
        </>
    );
}
