import React from 'react';
import { Modal } from 'antd';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export const FormModal = ({isOpen, toggle, url}: any) => {
    return (
        <Modal title='Form' visible={isOpen} onCancel={toggle} width={700} footer={null}>
            <Document file={url}> 
                <Page pageNumber={1} width={600}/>
            </Document>
        </Modal>
    )
}
