import React, { useState, useContext, useEffect } from 'react';
import { Steps, Layout, PageHeader } from 'antd';
import { useQuery } from '@apollo/client';
import { useMediaQuery } from 'react-responsive';
import { addDays } from 'date-fns';

import { TimeBooking } from './TimeBooking';
import { SlotBooking } from './SlotBooking';
import { BabyBooking } from './BabyBooking';
import { BookingForm } from './BookingForm';
import { BookingChildForm } from './BookingChildForm';
import { BookingConfirmation } from './BookingConfirmation';

import { GET_SETTINGS, GET_CALENDAR_SETTINGS } from '../../api/Query';

import { AppContext } from '../../context/AppContext';
import { BookingContext } from '../../context/BookingContext';

const { Step } = Steps;
const { Content } = Layout;

export const BookingPage = () => {
    const { setSidebarVisible } = useContext(AppContext);
    const [step, setStep] = useState(0);
    const [time, setTime] = useState('');
    const [date, setDate] = useState(new Date());
    const [service, setService] = useState({} as any);
    const [calendarId, setCalendarId] = useState('');
    const [locationId, setLocationId] = useState('');
    const [slotId, setSlotId] = useState('');
    const [formData, setFormData] = useState({} as any);
    const [formValid, setFormValid] = useState(false);
    const [bookingId, setBookingId] = useState('');
    const [todayBooking, setTodayBooking] = useState(true);
    const isMobile = useMediaQuery({query: '(max-width: 767px)'});

    const { data: calendarSettingsData } = useQuery(GET_CALENDAR_SETTINGS);
    const { data: settingsData } = useQuery(GET_SETTINGS);

    useEffect(() => {
        setSidebarVisible(false);
    }, [setSidebarVisible]);

    const nextStep = () => {
        let current_step: number = step;
        setStep(current_step += 1);
    }

    return (
        <Content>
            <PageHeader title="New Booking" style={{ backgroundColor: 'white' }} />
            <BookingContext.Provider value={{ step, nextStep, time, setTime, date, setDate, service, setService, calendarId, setCalendarId, locationId, setLocationId, slotId, setSlotId, bookingId, setBookingId, formData, setFormData, formValid, setFormValid, todayBooking, setTodayBooking }}>
                <Content style={{ padding: '1rem' }}>
                    {calendarSettingsData && calendarSettingsData.calendar_settings.bookings_enabled ? (
                        <>
                            {!isMobile && (
                            <Steps progressDot responsive style={{ width: '100%', backgroundColor: 'white', padding: '1rem', borderRadius: '1rem' }} current={step}>
                                <Step title="Service Selection" />
                                <Step title="Client Details" />
                                <Step title="Confirmation" />
                            </Steps>
                            )}
                            {step === 0 && (
                                <>
                                    {settingsData && settingsData.settings.booking_type === "time" && (
                                        <TimeBooking />
                                    )}
                                    {settingsData && settingsData.settings.booking_type === "slot" && (
                                        <SlotBooking />
                                    )}
                                    {settingsData && settingsData.settings.booking_type === "baby" && (
                                        <BabyBooking />
                                    )}
                                </>
                            )}
                            {settingsData && settingsData.settings.booking_type === "baby" ? (
                                <>
                                    {step === 1 && (
                                        <BookingChildForm />
                                    )}
                                    {step === 2 && (
                                        <BookingForm />
                                    )}
                                    {step === 3 && (
                                        <BookingConfirmation />
                                    )}
                                </>
                            ) : (
                                <>
                                    {step === 1 && (
                                        <BookingForm />
                                    )}
                                    {step === 2 && (
                                        <BookingConfirmation />
                                    )}
                                </>
                            )}
                        </>
                    ) : (
                        <div style={{ textAlign: 'center' }}>
                            <h1>Sorry we are not currently taking bookings at the moment.</h1>
                        </div>
                    )}
                </Content>
            </BookingContext.Provider>
        </Content>
    );
}
