import React, { useState } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Input, Layout, Button, PageHeader, Row, Col, Card, Tooltip, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import './ServicesContainer.css';

import { ServiceForm } from '../../components/Forms/ServiceForm/ServiceForm';

import { GET_SERVICES } from '../../api/Query';

const DELETE_SERVICE = gql`
    mutation ($service_id: ID!) {
        delete_service(service_id: $service_id) {
            _id
        }
    }
`;

const { Content } = Layout;
const { Meta } = Card;

export const ServicesContainer = () => {
    const [createService, setCreateService] = useState(false);
    const { data: servicesData } = useQuery(GET_SERVICES);
    const [deleteService] = useMutation(DELETE_SERVICE);
    const url = window.location.href.split('settings')[0];

    const deleteServiceHandler = (service_id: string) => {
		deleteService({ 
			variables: {
				service_id: service_id,
			},
			refetchQueries: [
				{ query: GET_SERVICES },
			]
		})
		.then(() => {
            message.error('Service Deleted');
        });
    }

    return (
        <Content>
            <PageHeader title="Services" style={{ backgroundColor: 'white', marginBottom: '1rem' }} extra={[
                <Button type="primary" key="create_service" onClick={() => setCreateService(!createService)}>{!createService ? 'Create Service' : 'Cancel'}</Button>
            ]}/>
            {createService ? (
                <ServiceForm setCreateService={setCreateService}/>
            ) : (
                <Row gutter={[16, 16]}>
                {servicesData && servicesData.services.map((service: any, index: number) => {
                    return (
                        <Col key={index} span={6}>
                            <Card actions={[
                                <Tooltip placement="bottom" title='Delete Service'>
                                    <DeleteOutlined onClick={() => deleteServiceHandler(service._id)} />
                                </Tooltip>
                            ]}>
                                <Meta title={service.name} description={`Price: $${service.price} Duration: ${service.duration}`} />
                                <br />
                                <Input value={`${url}time-booking/?${service._id}`}/>
                            </Card>
                        </Col>
                    );
                 })}
                </Row>
            )}
        </Content>
    );
}

