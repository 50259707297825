import React, { useContext } from 'react';
import { useQuery, gql } from '@apollo/client';
import { ConfigProvider, Empty, List, Radio } from 'antd';
import { convertFormattedMin } from '../../../tools/time-converter';
import { ClockCircleOutlined,  } from '@ant-design/icons';


import { BookingContext } from '../../../context/BookingContext';

const GET_TIMES = gql`
    query ($date: String!){
        available_bookings(date: $date)  
    }
`;

export const Times = () => {
    const { date, service, time, setTime } = useContext(BookingContext);
    const { data: timesData } = useQuery(GET_TIMES, {variables: {date: date}});

    const changeTimeHandler = (e: any) => {
        const time = e.target.value;
        setTime(time);
    }

    return (
        <>
            {Object.keys(service).length !== 0 ? (
                <>
                    <h4><b><ClockCircleOutlined /> Available Times</b></h4>
                    {timesData && 
                        <ConfigProvider renderEmpty={() => <Empty description="No Appointments Available"/>}>
                            <List dataSource={timesData.available_bookings} renderItem={(time_item: any)=> (
                                <Radio.Group style={{ display: 'block' }} onChange={changeTimeHandler} value={time}>
                                    <List.Item>
                                        <Radio value={time_item}>
                                            {convertFormattedMin(time_item)}
                                        </Radio>
                                    </List.Item>
                                </Radio.Group>
                            )}/>
                        </ConfigProvider>
                    }
                </>
            ) : (
                <div className="rounded-item" style={{width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                    <ClockCircleOutlined style={{ fontSize: '3rem', margin: '1rem 0'}}/>
                    <h3>No Times Available</h3>
                </div>
            )}
        </>
    );
}
