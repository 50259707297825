import React from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { message, Layout, PageHeader, Row } from 'antd';

import './IntegrationsContainer.css';

import { SquareIntegration } from '../../components/Integrations/Square';
import { StripeIntegration } from '../../components/Integrations/Stripe';
import { SMSIntegration } from '../../components/Integrations/SMS';
import { MailchimpIntegration } from '../../components/Integrations/Mailchimp';
import { EmailIntegration } from '../../components/Integrations/Email';
import { DropboxIntegration } from '../../components/Integrations/Dropbox';

import { GET_SETTINGS } from '../../api/Query';

const UPDATE_INTEGRATION = gql`
    mutation ($settings_id: String!, $name: String!, $value: Int!) {
        update_integration(settings_id: $settings_id, name: $name, value: $value) {
            _id
        }
    }
`;


const { Content } = Layout;

export const IntegrationsContainer = () => {
    const { data: settingsData } = useQuery(GET_SETTINGS);
    const [updateIntegration] = useMutation(UPDATE_INTEGRATION);

    const updateIntegrationHandler = (name: string, value: any) => {
        updateIntegration({
            variables: {
                settings_id: settingsData.settings._id,
                name: name,
                value: value ? 1 : 0
            },
            refetchQueries: [
                { query: GET_SETTINGS }
            ],
        }).then(() => {
            if(value) {
                message.success('Integration Enabled');
            } else {
                message.error('Integration Disabled');
            }
        });
    }

    return (
        <Content>
                <PageHeader title="Integrations" style={{backgroundColor: 'white', marginBottom: '1rem'}}/>
                {settingsData && 
                    <>
                        <Row gutter={[16, 16]} style={{ marginTop: '1rem' }}>
                            <SquareIntegration settings={settingsData.settings} updateIntegrationHandler={updateIntegrationHandler} /> 
                            <StripeIntegration settings={settingsData.settings} updateIntegrationHandler={updateIntegrationHandler} /> 
                            <SMSIntegration settings={settingsData.settings} updateIntegrationHandler={updateIntegrationHandler} /> 
                        </Row>
                        <Row gutter={[16, 16]} style={{ marginTop: '1rem' }}>
                            <MailchimpIntegration settings={settingsData.settings} updateIntegrationHandler={updateIntegrationHandler} /> 
                            <EmailIntegration settings={settingsData.settings} updateIntegrationHandler={updateIntegrationHandler} /> 
                            <DropboxIntegration settings={settingsData.settings} updateIntegrationHandler={updateIntegrationHandler} /> 
                        </Row>
                    </>
                }
        </Content>
    );
}

