export const convert24Hour = (time: number) => {
    const time_abbr = time >= 12 ? 'PM' : 'AM';

    if(time > 12){
        time = time - 12;
    }

    return `${time}${time_abbr}`;
}

export const convertFormatted = (input_time: string) => {
    const time_split = input_time.split(':');
    let time = parseInt(time_split[0]);
    const time_abbr = time >= 12 ? 'PM' : 'AM';

    if(time > 12){
        time = time - 12;
    }

    return `${time}${time_abbr}`;
}

export const convertFormattedMin = (input_time: string) => {
    const time_split = input_time.split(':');
    let time = parseInt(time_split[0]);
    const time_abbr = time >= 12 ? 'PM' : 'AM';

    if(time > 12){
        time = time - 12;
    }

    return `${time}:${time_split[1]} ${time_abbr}`;
}

export const getDayIndex = (day: string) => {
    const dayOfWeekMap: any = {
        Monday: 0,
        Tuesday: 1,
        Wednesday: 2,
        Thursday: 3,
        Friday: 4,
        Saturday: 5,
        Sunday: 6,
    };
    return dayOfWeekMap[day];
}
