import React from 'react';
import { useMutation, gql } from '@apollo/client';
import { Form, Layout, Button, Input, message } from 'antd';

import './ServiceForm.css';

import { GET_SERVICES } from '../../../api/Query';

const CREATE_SERVICE = gql`
    mutation ($name: String!, $price: Int!, $duration: String!) {
        create_service(service_input: {name: $name, price: $price, duration: $duration}) {
            _id
        }
    }
`;

type Props = {
    setCreateService: (createService: boolean) => void
}

const { Content } = Layout;

export const ServiceForm  = ({setCreateService}: Props) => {
    const [createService] = useMutation(CREATE_SERVICE);
     
    const onSubmit = (form_data: any) => {
		createService({ 
			variables: {
				name: form_data.service_name,
				price: parseInt(form_data.service_price),
                duration: form_data.service_duration,
			},
			refetchQueries: [
				{ query: GET_SERVICES },
			]
		})
		.then(() => {
            message.success('Service Created');
            setCreateService(false);
		});
    }

    return (
        <Content className="service-form">
            <Form layout="vertical" onFinish={onSubmit}>
                <Form.Item name="service_name" label="Service Name" rules={[{ required: true, message: 'Please provide a service name'}]}>
                    <Input placeholder="Service Name"/>
                </Form.Item>
                <Form.Item name="service_price" label="Price" rules={[{ required: true, message: 'Please provide a price'}]}>
                    <Input placeholder="Price"/>
                </Form.Item>
                <Form.Item name="service_duration" label="Duration" rules={[{ required: true, message: 'Please provide a duration'}]}>
                    <Input placeholder="Duration"/>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">Create Service</Button>
                </Form.Item>
            </Form>
        </Content>
    );
}
