import React from 'react';
import { Button, Popconfirm } from 'antd';

export const CalendarButton = ({calendarLoaded, createCalendarHandler, deleteCalendarHandler}: any) => {
    const createCalendar = () => {
        createCalendarHandler();
    }
    return (

        <>
            {!calendarLoaded ? (
                <Button type="primary" onClick={createCalendar}>Create Calendar</Button>
            ) : (
                <Popconfirm title="Are you sure to delete this calendar?" onConfirm={deleteCalendarHandler} 
                    okText="Yes"
                    cancelText="No">
                    <Button type="primary" danger>Delete Calendar</Button>
                </Popconfirm>
            )}
        </>
    );
}
