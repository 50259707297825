import React, { useContext } from 'react';
import { Button } from 'antd';

import { CalendarContext } from '../../context/CalendarContext';

export const BookingButton = ({rowId, slotId, bookingId, available}: any) => {
    const { setBookingId, setViewBookingModal, setSlotId, setRowId, setCreateBookingModal } = useContext(CalendarContext);

    const openCreateBookingModal = () => {
        setSlotId(slotId);
        setRowId(rowId);
        setCreateBookingModal(true);
    }

    const openViewBookingModal = () => {
        setBookingId(bookingId);
        setViewBookingModal(true);
    }

    return (
        <div style={{padding: '1rem'}}>
            {available ? (
                <Button type="primary" block onClick={openCreateBookingModal}>Book Now</Button>
            ) : (
                <Button type="primary" block style={{backgroundColor: 'green', borderColor: 'green'}} onClick={openViewBookingModal}>View Booking</Button>
            )}
        </div>
    );
}
