import React from 'react';

type BookingContextType = {
    step: number;
    nextStep: () => void;
    time: string;
    setTime: (time: string) => void;
    date: Date;
    setDate: (date: Date) => void;
    service: any;
    setService: (service: any) => void;
    calendarId: string;
    setCalendarId: (calendar_id: string) => void;
    locationId: string;
    setLocationId: (location_id: string) => void;
    slotId: string;
    setSlotId: (slot: string) => void;
    bookingId: string;
    setBookingId: (booking_id: string) => void;
    formData: any;
    setFormData: (form_data: any) => void;
    formValid: boolean;
    setFormValid: (form_valid: boolean) => void;
    todayBooking: boolean;
    setTodayBooking: (today_booking: boolean) => void;
}

export const BookingContext = React.createContext({} as BookingContextType);
