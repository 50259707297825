import React, { useContext } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Button, Modal, Form, Input, message } from 'antd';

import { AppContext } from '../../context/AppContext';

import { GET_SLOT, GET_ROW } from '../../api/Query';

const CREATE_BOOKING = gql`
    mutation ($date: String!, $slot_id: ID!, $service_id: ID!, $first_name: String!, $last_name: String!, $email: String!, $mobile: Int!, $notes: String){
        create_booking(booking_input: {date: $date, slot_id: $slot_id, service_id: $service_id, first_name: $first_name, last_name: $last_name, email: $email, mobile: $mobile, notes: $notes}) {
            _id
        }
    }
`;

const { TextArea } = Input;

export const CreateBookingModal = ({isOpen, toggle, slotId, rowId}: any) => {
    const { selectedDate } = useContext(AppContext);
    const [createBooking] = useMutation(CREATE_BOOKING);

    const { data: slotData } = useQuery(GET_SLOT, {
        variables: {
            slot_id: slotId
        },
        skip: !slotId
    });

	const onSubmit = (form_data: any) => {
		createBooking({ 
			variables: {
                date: selectedDate,
                slot_id: slotId,
                service_id: slotData.slot.service_id._id,
				first_name: form_data.first_name,
				last_name: form_data.last_name,
				email: form_data.email,
				mobile: parseInt(form_data.mobile),
                notes: form_data.notes,
			},
			refetchQueries: [
				{ query: GET_ROW, variables: { row_id: rowId} }
			]
		})
		.then(() => {
            toggle();
            message.success('Booking Created');
        });
	}

    return (
        <Modal title="New Booking" visible={isOpen} okText="Create Booking" onCancel={toggle} footer={null}>
            <Form layout="vertical" onFinish={onSubmit}>
                <Form.Item name="first_name" label="First Name" rules={[{ required: true, message: 'Please provide a first name'}]}>
                    <Input size="large" placeholder="First Name" style={{ borderRadius: '1rem' }}/>
                </Form.Item>
                <Form.Item name="last_name" label="Last Name" rules={[{ required: true, message: 'Please provide a last name'}]}>
                    <Input size="large" placeholder="Last Name" style={{ borderRadius: '1rem' }}/>
                </Form.Item>
                <Form.Item name="mobile" label="Mobile" rules={[{ required: true, min: 10, max: 10, message: 'Please provide a mobile'}]}>
                    <Input size="large" placeholder="Mobile" style={{ borderRadius: '1rem' }}/>
                </Form.Item>
                <Form.Item name="email" label="Email" rules={[{ required: true, type: 'email', message: 'Please provide a email'}]}>
                    <Input size="large" placeholder="Email" style={{ borderRadius: '1rem' }}/>
                </Form.Item>
                <Form.Item name="notes" label="Notes">
                    <TextArea rows={4} placeholder="Notes" style={{ borderRadius: '1rem' }} />
                </Form.Item>
                <Button block type="primary" size="large" shape="round" htmlType="submit">
                    Make Booking 
                </Button>
            </Form>
      </Modal>
    )
}

