import React, { useState } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Empty, ConfigProvider, Input, Modal, Button, List, message } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { arrayStaffSearch } from '../../tools/array-search';

import { GET_ALL_STAFF, GET_ROW } from '../../api/Query';

const CHANGE_STAFF = gql`
    mutation ($slot_id: ID!, $staff_id: ID!){
        change_slot_staff(slot_id: $slot_id, staff_id: $staff_id){
            _id
        }
    }
`;

const REMOVE_STAFF = gql`
    mutation ($slot_id: ID!, $staff_id: ID!){
        remove_slot_staff(slot_id: $slot_id, staff_id: $staff_id){
            _id
        }
    }
`;

const { Search } = Input;
 
export const StaffModal = ({isOpen, toggle, staffId, slotId, rowId}: any) => {
    const [staff, setStaff] = useState([]);
	const [changeStaff] = useMutation(CHANGE_STAFF);
	const [removeStaff] = useMutation(REMOVE_STAFF);

	const { data: staffData } = useQuery(GET_ALL_STAFF, {
        onCompleted: (data: any) => {
            setStaff(data.all_staff)
        }
    });

    const changeStaffHandler = (staff_id: string) => {
        if(staff_id === '') {
            removeStaff({
                variables: {
                    slot_id: slotId,
                    staff_id: staffId._id
                },
                refetchQueries: [
                    { query: GET_ROW, variables: { row_id: rowId} }
                ]
            })
            .then(() => {
                toggle();
                message.success('Staff Changed');
            });
        } else {
            changeStaff({
                variables: {
                    slot_id: slotId,
                    staff_id: staff_id
                },
                refetchQueries: [
                    { query: GET_ROW, variables: { row_id: rowId} }
                ]
            })
            .then(() => {
                toggle();
                message.success('Staff Changed');
            });
        }
    }

    const searchHandler = (e: any) => {
        let value = e.target.value;
        if(value.length > 2) {
            let search = arrayStaffSearch(staff, value);
            setStaff(search);
        } else {
            setStaff(staffData.all_staff);
        }
    }

    return (
        <Modal title='Assign Staff' visible={isOpen} onCancel={toggle} footer={null} >
            {staff && staffId && (
                <Button type="primary" style={{ marginBottom: '1rem' }} block danger onClick={() => changeStaffHandler('')}>Remove Staff</Button>
            )}
            <Search placeholder="Search" onChange={searchHandler} />
            <hr />
            {staffData && (
                <ConfigProvider renderEmpty={() => <Empty description="No Staff Members"/>}>
                    <List dataSource={staff} renderItem={(staff: any)=> (
                        <List.Item actions={[<Button type="primary" onClick={() => changeStaffHandler(staff._id)}>Select</Button>]}>
                            <UserOutlined /> {staff.first_name} {staff.last_name}
                        </List.Item>
                    )}/>
                </ConfigProvider>
            )}
        </Modal>
    )
}
