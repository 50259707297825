import React, { useState } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Switch, message, Col, Form, Input, Button } from 'antd';
import { CreditCardOutlined, SettingOutlined } from '@ant-design/icons';

import { GET_STRIPE_SETTINGS } from '../../api/Query';

const UPDATE_STRIPE = gql`
    mutation ($settings_id: String!, $secret: String!, $publishable: String!) {
        update_stripe_settings(settings_id: $settings_id, stripe_settings_input: {secret: $secret, publishable: $publishable}) {
            _id
        }
    }
`;

type Props = {
    settings: any,
    updateIntegrationHandler: (name: string, value: number) => void
};

export const StripeIntegration = ({settings, updateIntegrationHandler}: Props) => {
    const [showSettings, setShowSettings] = useState(false);
    const { data: stripeSettingsData } = useQuery(GET_STRIPE_SETTINGS);
    const [updateStripe] = useMutation(UPDATE_STRIPE);

    const updateStripeHandler = (form_data: any) => {
        form_data.settings_id = stripeSettingsData.stripe_settings._id;
        updateStripe({
            variables: {
                ...form_data
            },
            refetchQueries: [
                { query: GET_STRIPE_SETTINGS }
            ],
        }).then(() => {
            message.success('Stripe Settings Updated');
            setShowSettings(false);
        });
    }

    return (
        <Col flex="1" span={8}>
            <div className="integrations-item">
                {showSettings ? (
                    <>
                        {settings.stripe_enabled === 1 && stripeSettingsData && (
                            <Form layout="vertical" onFinish={updateStripeHandler} initialValues={stripeSettingsData.stripe_settings}>
                                <Form.Item name="secret" label="App Secret">
                                    <Input placeholder="Stripe App Secret" />
                                </Form.Item>
                                <Form.Item name="publishable" label="App Publishable">
                                    <Input placeholder="Stripe Publishable" />
                                </Form.Item>
                                <Button type="primary" block htmlType="submit">Save</Button>
                            </Form>
                        )}
                    </>
                ) : (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <CreditCardOutlined style={{ fontSize: '3rem' }}/>
                        <h1>Stripe</h1>
                        <Switch defaultChecked={settings.stripe_enabled} onChange={(value: any) => updateIntegrationHandler('stripe_enabled', value)}/>
                        <hr />
                        {settings.stripe_enabled === 1 && 
                            <SettingOutlined style={{ cursor: 'pointer', fontSize: '1rem' }} onClick={() => setShowSettings(!showSettings)}/>
                        }
                    </div>
                )}
            </div>
        </Col>
    );
}
