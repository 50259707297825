export const generateTemplate = (form_data: any, selectedService: string, staffColumn: boolean) => {
    const start_time = form_data.time[0].format("HH");
    const end_time = form_data.time[1].format("HH");
    const number_slots = (end_time - start_time);
    let template = {name: form_data.template_name, rooms: [] as any, staff_column: staffColumn};
    let slot_time = start_time;
    const rooms = form_data.rooms;
    for(let i = 0; i < rooms; i++){
        let room: any = {
            name: `Room #${i+1}`,
            slots: []
        };
        for(let j = 0; j < number_slots; j++) {
            room.slots.push({slot_service: selectedService, slot_time: `${slot_time}:00`});
            slot_time++;
        }
        template.rooms.push(room);
    }
    return template;
}
