import React, { useState } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Tooltip, Row, Col, Card, Layout, Button, PageHeader, message } from 'antd';
import { format, parseISO } from 'date-fns';
import { DeleteOutlined } from '@ant-design/icons';

import './VoucherContainer.css';

import { VoucherForm } from '../../components/Forms/VoucherForm/VoucherForm';

import { GET_VOUCHERS } from '../../api/Query';

const DELETE_VOUCHER = gql`
    mutation ($voucher_id: ID!) {
        delete_voucher(voucher_id: $voucher_id) {
            _id
        }
    }
`;

const { Content } = Layout;
const { Meta } = Card;

export const VoucherContainer = () => {
    const [createVoucher, setCreateVoucher] = useState(false);
    const { data: vouchersData } = useQuery(GET_VOUCHERS);
    const [deleteVoucher] = useMutation(DELETE_VOUCHER);

    const deleteVoucherHandler = (voucher_id: string) => {
		deleteVoucher({ 
			variables: {
				voucher_id: voucher_id,
			},
			refetchQueries: [
				{ query: GET_VOUCHERS },
			]
		})
		.then(() => {
            message.error('Voucher Deleted');
        });
    }

    return (
        <Content>
            <PageHeader title="Vouchers" style={{ backgroundColor: 'white', marginBottom: '1rem' }} extra={[
                <Button type="primary" key="create_voucher" onClick={() => setCreateVoucher(!createVoucher)}>{!createVoucher ? 'Create Voucher' : 'Cancel'}</Button>
            ]}/>
            {createVoucher ? (
                <VoucherForm setCreateVoucher={setCreateVoucher}/>
            ) : (
                <Row gutter={[16, 16]}>
                {vouchersData && vouchersData.vouchers.map((voucher: any, index: number) => {
                    return (
                        <Col key={index} span={6}>
                            <Card actions={[
                                <Tooltip placement="bottom" title='Delete Voucher'>
                                    <DeleteOutlined onClick={() => deleteVoucherHandler(voucher._id)} />
                                </Tooltip>
                            ]}>
                                <Meta title={`Voucher Valid: ${voucher.valid ? 'Yes' : 'No'}`} />
                                <p>Email: {voucher.email}</p>
                                <p>Number: {voucher.number}</p>
                                <p>Uses: {voucher.uses}</p>
                                <p>Remaining Uses: {voucher.remaining_uses}</p>
                                <p>Date Purchased: {format(parseISO(voucher.date), 'cccc do MMMM')}</p>
                                <p>Service: {voucher.service.name}</p>
                            </Card>
                        </Col>
                    );
                 })}
                </Row>
            )}
        </Content>
    );
}

