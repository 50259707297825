import React, { useState, useContext } from 'react';
import { useMutation, gql } from '@apollo/client';
import { Modal, message, Form, Button, TimePicker, InputNumber, Checkbox } from 'antd';

import './CreateRowModal.css';

import { ServiceSelect } from '../../Selects/ServiceSelect';
import { AppContext } from '../../../context/AppContext';

import { GET_CALENDAR } from '../../../api/Query';

export const CREATE_ROW = gql`
    mutation ($calendar_id: ID!, $time: String!, $service: String!, $rooms: Int!, $staff_slot: Boolean!){
        create_row(row_input: {calendar_id: $calendar_id, time: $time, service: $service, rooms: $rooms, staff_slot: $staff_slot}) {
			_id
		}
	}
`;

export const CreateRowModal = ({isOpen, toggle, calendarId}: any) => {
	const [createRow] = useMutation(CREATE_ROW); 
    const { selectedDate } = useContext(AppContext);
    const [selectedService, setSelectedService] = useState('');
    const [staffSlot, setStaffSlot] = useState(false);

    const onSubmit = (form_data: any) => {
		createRow({ 
			variables: {
				calendar_id: calendarId,
                time: form_data.row_time,
                service: selectedService,
                rooms: form_data.row_rooms,
                staff_slot: staffSlot 
			},
			refetchQueries: [
                { query: GET_CALENDAR, variables: { date: selectedDate} }
			]
		})
		.then(() => {
            toggle();
			message.success('Row Created');
		});
    }

    const setServiceHandler = (service: any) => {
        setSelectedService(service);
    }

    return (
        <Modal title="Create Calendar" visible={isOpen} onCancel={toggle}>
            <Form layout="vertical" onFinish={onSubmit}>
                <Form.Item name="row_time" label="Row Time" rules={[{ required: true, message: 'Please provide a row time'}]}>
                    <TimePicker />
                </Form.Item>
                <Form.Item name="row_service" label="Row Service"> 
                    <ServiceSelect changeServiceHandler={(service: any) => setServiceHandler(service)} />
                </Form.Item>
                <Form.Item name="row_rooms" label="Row Rooms" rules={[{ required: true }]}> 
                    <InputNumber defaultValue={0} />
                </Form.Item>
                <Form.Item name="staff_slot"> 
                    <Checkbox checked={staffSlot} onChange={() => setStaffSlot(!staffSlot)}>Create Staff Slot</Checkbox>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">Create Row</Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}
