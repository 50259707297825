import { useQuery, gql } from '@apollo/client'

const isLoggedIn = gql`
    query isLoggedIn {
        isLoggedIn
    }
`;
export function useAuth() {
    const { data } = useQuery(isLoggedIn)

    return {
        isLoggedIn: data && data.isLoggedIn,
    }
}
