import React, { useState, useContext } from 'react';
import { Button, Checkbox, Form as AntForm, Input } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import { BookingContext } from '../../../context/BookingContext';

export const Form = ({callback}: any) => {
    const { setFormData, setFormValid, formValid } = useContext(BookingContext);
    const [marketing, setMarketing] = useState(true);
    console.log(callback);

    const submitFormHandler = (form_data: any) => {
        form_data.marketing = marketing;
        setFormData(form_data);
        setFormValid(true);
        callback();
    }

    return (
        <div className="rounded-item">
            <h4><b>Client Details</b></h4>
            <AntForm layout="vertical" onFinish={submitFormHandler}>
                <AntForm.Item name="first_name" label="First Name" rules={[{ required: true, message: 'Please provide a first name'}]}>
                    <Input placeholder="First Name" size="large" disabled={formValid} style={{ borderRadius: '1rem' }} />
                </AntForm.Item>
                <AntForm.Item name="last_name" label="Last Name" rules={[{ required: true, message: 'Please provide a last name'}]}>
                    <Input placeholder="Last Name" size="large" disabled={formValid} style={{ borderRadius: '1rem' }} />
                </AntForm.Item>
                <AntForm.Item name="mobile" label="Mobile" rules={[{ required: true, min: 10, max: 10, message: 'Please provide a mobile'}]}>
                    <Input placeholder="Mobile" size="large" disabled={formValid} style={{ borderRadius: '1rem' }} />
                </AntForm.Item>
                <AntForm.Item name="email" label="Email" rules={[{ required: true, type: 'email', message: 'Please provide a email'}]}>
                    <Input placeholder="Email" size="large" disabled={formValid} style={{ borderRadius: '1rem' }} />
                </AntForm.Item>
                <AntForm.Item name="marketing"> 
                    <h3>
                        <Checkbox checked={marketing} onChange={() => setMarketing(!marketing)}>
                            <InfoCircleOutlined/> Would you like to recieve emails about future promotions and special offers?
                        </Checkbox>
                    </h3>
                </AntForm.Item>
                {!formValid && (
                    <Button block type="primary" size="large" shape="round" htmlType="submit">
                        Review & Payment
                    </Button>
                )}
            </AntForm>
        </div>
    );
}
