import React, { useContext, useState } from 'react';
import { Row, Col, Layout } from 'antd';
import { useMediaQuery } from 'react-responsive';

import './style.css';

import { BookingContext } from '../../context/BookingContext';

import { ChildForm } from './components/ChildForm';

const { Content } = Layout;

export const BookingChildForm = () => {
    const { formData, formValid } = useContext(BookingContext);
    const isMobile = useMediaQuery({query: '(max-width: 767px)'});

    return (
        <Content style={{marginTop: '1rem' }}>
            <Row gutter={[16, 16]}>
                {isMobile ? (
                    <>
                        <Col span={24}>
                        </Col>
                    </>
                ) : (
                    <>
                        {formData && (
                            <>
                            </>
                          )}
                    </> 
                )}
            </Row>
        </Content>
    )
}

