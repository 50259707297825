import React, { useState } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Switch, message, Col, Form, Input, Button } from 'antd';
import { MessageOutlined, SettingOutlined } from '@ant-design/icons';

import { GET_SMS_SETTINGS } from '../../api/Query';

const UPDATE_SMS = gql`
    mutation ($settings_id: String!, $account_sid: String!, $access_token: String!, $phone_number: String!) {
        update_sms_settings(settings_id: $settings_id, sms_settings_input: {account_sid: $account_sid, access_token: $access_token, phone_number: $phone_number}) {
            _id
        }
    }
`;

type Props = {
    settings: any,
    updateIntegrationHandler: (name: string, value: number) => void
};

export const SMSIntegration = ({settings, updateIntegrationHandler}: Props) => {
    const [showSettings, setShowSettings] = useState(false);
    const { data: smsSettingsData } = useQuery(GET_SMS_SETTINGS);
    const [updateSMS] = useMutation(UPDATE_SMS);

    const updateSMSHandler = (form_data: any) => {
        form_data.settings_id = smsSettingsData.sms_settings._id;
        updateSMS({
            variables: {
                ...form_data
            },
            refetchQueries: [
                { query: GET_SMS_SETTINGS }
            ],
        }).then(() => {
            message.success('SMS Settings Updated');
            setShowSettings(false);
        });
    }

    return (
        <Col flex="1" span={8}>
            <div className="integrations-item">
                {showSettings ? (
                    <>
                        {settings.sms_enabled === 1 && smsSettingsData && (
                            <Form layout="vertical" onFinish={updateSMSHandler} initialValues={smsSettingsData.sms_settings}>
                                <Form.Item name="account_sid" label="Account SID">
                                    <Input placeholder="SMS Account SID" />
                                </Form.Item>
                                <Form.Item name="access_token" label="Access Token">
                                    <Input placeholder="SMS Access Token" />
                                </Form.Item>
                                <Form.Item name="phone_number" label="Phone Number (Can be text between 1-12 characters)">
                                    <Input placeholder="SMS Phone Number" />
                                </Form.Item>
                                <Button type="primary" block htmlType="submit">Save</Button>
                            </Form>
                        )}
                    </>
                ) : (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <MessageOutlined style={{ fontSize: '3rem' }}/>
                        <h1>SMS</h1>
                        <Switch defaultChecked={settings.sms_enabled} onChange={(value: any) => updateIntegrationHandler('sms_enabled', value)}/>
                        <hr />
                        {settings.sms_enabled === 1 && 
                            <SettingOutlined style={{ cursor: 'pointer', fontSize: '1rem' }} onClick={() => setShowSettings(!showSettings)}/>
                        }
                    </div>
                )}
            </div>
        </Col>
    );

}
