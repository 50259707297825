import React from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { List, Switch, Row, Col, message, Layout, PageHeader, Form, Input, InputNumber, Button } from 'antd';

import './CalSettingsContainer.css';

import { DaySelect } from '../../components/Selects/DaySelect';

import { GET_CALENDAR_SETTINGS, GET_BLOCKOUT_SETTINGS } from '../../api/Query';

const UPDATE_CALENDAR_SETTINGS = gql`
    mutation ($settings_id: ID!, $bookings_enabled: Int, $start_time: Int, $finish_time: Int, $block_out_before: Int, $block_out_after: Int, $client_blockout: Int, $confirmation_body: String, $thanks_body: String){
        update_calendar_settings(settings_id: $settings_id, calendar_settings_input: {bookings_enabled: $bookings_enabled, start_time: $start_time, finish_time: $finish_time, block_out_before: $block_out_before, block_out_after: $block_out_after, client_blockout: $client_blockout, confirmation_body: $confirmation_body, thanks_body: $thanks_body}) {
            _id
		}
	}
`;

const ADD_BLOCKOUT_DAY = gql`
    mutation ($settings_id: ID!, $day: String!){
        add_blockout_day(settings_id: $settings_id, day: $day) {
            _id
		}
	}
`;

const REMOVE_BLOCKOUT_DAY = gql`
    mutation ($settings_id: ID!, $day: String!){
        remove_blockout_day(settings_id: $settings_id, day: $day) {
            _id
		}
	}
`;

const { Content } = Layout;
const { TextArea } = Input;

export const CalSettingsContainer = () => {
    const { data: settingsData } = useQuery(GET_CALENDAR_SETTINGS);
    const { data: blockoutData } = useQuery(GET_BLOCKOUT_SETTINGS);
	const [updateSettings] = useMutation(UPDATE_CALENDAR_SETTINGS);
	const [addBlockoutDay] = useMutation(ADD_BLOCKOUT_DAY);
	const [removeBlockoutDay] = useMutation(REMOVE_BLOCKOUT_DAY);

    const onSubmit = (form_data: any) => { 
        form_data.settings_id = settingsData.calendar_settings._id;
        form_data.bookings_enabled = form_data.bookings_enabled ? 1 : 0;
        updateSettings({ 
			variables: {...form_data},
			refetchQueries: [
				{ query: GET_CALENDAR_SETTINGS },
			]
		})
		.then(() => {
            message.success('Settings Updated');
		});
    }

    const addBlockoutHandler = (day: string) => {
        addBlockoutDay({ 
			variables: {
                settings_id: blockoutData.blockout_settings._id,
                day: day
            },
			refetchQueries: [
				{ query: GET_BLOCKOUT_SETTINGS },
			]
		})
		.then(() => {
            message.success('Blockout Day Added');
		});
    }

    const removeBlockoutHandler = (day: string) => {
        removeBlockoutDay({ 
			variables: {
                settings_id: blockoutData.blockout_settings._id,
                day: day
            },
			refetchQueries: [
				{ query: GET_BLOCKOUT_SETTINGS },
			]
		})
		.then(() => {
            message.error('Blockout Day Removed');
		});
    }

    return (
        <Content>
            <PageHeader title="Calendar Settings" style={{backgroundColor: 'white', marginBottom: '1rem'}}/>
            {settingsData && (
                <Row gutter={[16, 16]}>
                    <Col lg={8}>
                        <div className="rounded-item">
                            <h4>Calendar Settings</h4>
                            <hr/>
                            <Form onFinish={onSubmit} initialValues={settingsData.calendar_settings}>
                                <Form.Item name="bookings_enabled" label="Bookings Enabled" valuePropName="checked">
                                    <Switch />
                                </Form.Item>
                                <Form.Item name="start_time" label="Start Time">
                                    <InputNumber style={{ width: '100%' }}/>
                                </Form.Item>
                                <Form.Item name="finish_time" label="Finish Time">
                                    <InputNumber style={{ width: '100%' }}/>
                                </Form.Item>
                                <Form.Item name="block_out_before" label="Block Out Before">
                                    <InputNumber style={{ width: '100%' }}/>
                                </Form.Item>
                                <Form.Item name="block_out_after" label="Block Out After">
                                    <InputNumber style={{ width: '100%' }}/>
                                </Form.Item>
                                <Form.Item name="client_blockout" label="Client Blockout">
                                    <InputNumber style={{ width: '100%' }}/>
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" size="large" block shape="round" htmlType="submit">Save</Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </Col>
                    <Col lg={8}>
                        <div className="rounded-item">
                            <h4>Email Settings</h4>
                            <hr />
                            <Form onFinish={onSubmit} layout="vertical" initialValues={settingsData.calendar_settings}>
                                <Form.Item name="confirmation_body" label="Confirmation Email Body">
                                    <TextArea rows={4} />
                                </Form.Item>
                                <Form.Item name="thanks_body" label="Thanks Email Body">
                                    <TextArea rows={4} />
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" size="large" block shape="round" htmlType="submit">Save</Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </Col>
                    <Col lg={8}>
                        <div className="rounded-item">
                            <h4>Blockout Days</h4>
                            <hr />
                            {blockoutData && 
                                <>
                                <DaySelect type="full" changeDayHandler={addBlockoutHandler}/>
                                <hr />
                                <List dataSource={blockoutData.blockout_settings.days} renderItem={(day: any)=> (
                                    <List.Item actions={[
                                        <Button type="primary" onClick={() => removeBlockoutHandler(day)}>
                                            Remove
                                        </Button>
                                    ]}>
                                        <List.Item.Meta title={day} />
                                    </List.Item>
                                )}/>
                                </>
                            }
                        </div>
                    </Col>
                </Row>
            )}
        </Content>
    );
}

