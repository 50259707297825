import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { message, Form, Modal, Checkbox, TimePicker, DatePicker } from 'antd';

const BLOCKOUT_TIME = gql`
    mutation ($date: String!, $start_time: String, $end_time: String) {
        blockout_booking(date: $date, start_time: $start_time, end_time: $end_time) {
            _id
        }
    }
`;

const { RangePicker } = TimePicker;

export const BlockoutModal = ({isOpen, toggle}: any) => {
    const [blockoutTime] = useMutation(BLOCKOUT_TIME);
    const [form] = Form.useForm();
    const [allDay, setAllDay] = useState(false);

    const onSubmit = (form_data: any) => {
        let start_time;
        let end_time;
        if(allDay) {
            start_time = new Date(`${form_data.date.format('yyyy-MM-DD')}T00:00`);
            end_time = new Date(`${form_data.date.format('yyyy-MM-DD')}T23:59`);
        } else {
            start_time = new Date(`${form_data.date.format('yyyy-MM-DD')}T${form_data.time[0].format('HH:mm')}`);
            end_time = new Date(`${form_data.date.format('yyyy-MM-DD')}T${form_data.time[1].format('HH:mm')}`);
        }
		blockoutTime({ 
			variables: {
                date: form_data.date,
                start_time: start_time,
                end_time: end_time 
			}
		})
		.then(() => {
            message.error('Blockout Added');
            window.location.reload();
        });
    }

    return (
        <Modal title="Blockout Time" visible={isOpen} onCancel={toggle} okText="Blockout Time" cancelText="Close" onOk={() => {
            form.validateFields().then((form_data: any) => { 
                form.resetFields();
                onSubmit(form_data);
            }).catch((info: any) => {
                console.log('Validate Failed:', info);
            });
        }}>
            <Form form={form} layout="vertical" onFinish={onSubmit}>
                <Form.Item name="date"> 
                    <DatePicker />
                </Form.Item>
                <Checkbox onChange={(e: any) => setAllDay(e.target.checked)}>All Day?</Checkbox>
                {!allDay &&
                    <Form.Item name="time" label="Time"> 
                        <RangePicker format="HH:mm"/>
                    </Form.Item>
                }
            </Form>
        </Modal>
    )
}
