import React, { useState } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Layout, Button, PageHeader, Row, Col, Card, Tooltip, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import './UserContainer.css';

import { UserForm } from '../../components/Forms/UserForm/UserForm';

import { GET_USERS } from '../../api/Query';

const DELETE_USER = gql`
    mutation ($user_id: ID!) {
        delete_user(user_id: $user_id) {
            _id
        }
    }
`;

const { Content } = Layout;
const { Meta } = Card;

export const UserContainer = () => {
    const [createUser, setCreateUser] = useState(false);
	const { data: userData } = useQuery(GET_USERS);
    const [deleteUser] = useMutation(DELETE_USER);

    const deleteUserHandler = (user_id: string) => {
		deleteUser({ 
			variables: {
				user_id: user_id,
			},
			refetchQueries: [
				{ query: GET_USERS },
			]
		})
		.then(() => {
            message.error('User Deleted');
        });
    }

    return (
        <Content>
            <PageHeader title="Users" style={{ backgroundColor: 'white', marginBottom: '1rem' }} extra={[
                <Button type="primary" key="create_user" onClick={() => setCreateUser(!createUser)}>{!createUser ? 'Create User' : 'Cancel'}</Button>
            ]}/>
            {createUser ? (
                <UserForm setCreateUser={setCreateUser}/>
            ) : (
                <Row gutter={[16, 16]}>
                    {userData && userData.users.map((user: any, index: number) => {
                        return (
                            <Col key={index} span={6}>
                                <Card actions={[
                                    <Tooltip placement="bottom" title='Delete User'>
                                        <DeleteOutlined onClick={() => deleteUserHandler(user._id)} />
                                    </Tooltip>
                                ]}>
                                    <Meta title={user.email} />
                                </Card>
                            </Col>
                        );
                     })}
                </Row>
            )}
        </Content>
    );
}
