import React, { useState } from 'react';
import { Layout } from 'antd';
import { Calendar as BigCalendar, dateFnsLocalizer } from 'react-big-calendar'
import { useQuery, gql } from '@apollo/client';
import { addDays, startOfDay, endOfDay, format, parse, startOfWeek, getDay, set } from 'date-fns'

import './TimeCalendar.css';

import { CalStaffString } from '../Strings/CalStaffString';
import { CreateTimeBookingModal } from '../Modals/CreateTimeBookingModal';
import { ViewTimeBookingModal } from '../Modals/ViewTimeBookingModal';
import { getDayIndex } from '../../tools/time-converter';

import { GET_CALENDAR_SETTINGS } from '../../api/Query';
import { GET_BLOCKOUT_SETTINGS } from '../../api/Query';

const GET_BOOKINGS = gql`
	query ($date: String!){
		bookings_by_week(date: $date) {
			_id
            booking_type
            first_name
            last_name
            service_id {
                name
            } 
            start_time
            end_time
		}
	}
`;

const locales = {
   'en-AU': require('date-fns/locale/en-AU'),
}

const localizer = dateFnsLocalizer({
   format,
   parse,
   startOfWeek: () => startOfWeek(new Date(), { weekStartsOn: 1 }),
   getDay,
   locales,
});

const { Content } = Layout;

export const TimeCalendar = () => {
    const [createBookingModal, setCreateBookingModal] = useState(false);
    const [viewBookingModal, setViewBookingModal] = useState(false);
    const [selectedSlot, setSelectedSlot] = useState({});
    const [bookingId, setBookingId] = useState('');
    const [calendarDate, setCalendarDate] = useState(new Date());
    const [slots, setSlots] = useState<any>([]);

    useQuery(GET_BOOKINGS, {
		variables: {date: calendarDate},
        skip: !calendarDate,
        onCompleted: (data: any) => {
            data.bookings_by_week.forEach((booking: any) => {
                const event = {
                    booking: booking,
                    title: `${booking.first_name} ${booking.last_name}`, 
                    end: new Date(booking.end_time), 
                    start: new Date(booking.start_time)
                }
                setSlots((slots: any) => [...slots, event]);
            });
        }
    });

    useQuery(GET_BLOCKOUT_SETTINGS, {
        variables: {date: calendarDate},
        skip: !calendarDate,
        onCompleted: (data: any) => {
            const start_week_date = startOfWeek(calendarDate, {weekStartsOn: 1});
            data.blockout_settings.days.forEach((day: string) => {
                const day_index = getDayIndex(day);
                const date = addDays(start_week_date, day_index); 
                const blockout_event = {
                    booking_type: 'blockout',
                    title: 'Blockout',
                    end: endOfDay(date),
                    start: startOfDay(date), 
                };
                setSlots((slots: any) => [...slots, blockout_event]);
            });
        }
    });

    const { data: calendarSettingsData } = useQuery(GET_CALENDAR_SETTINGS);

    const toggleCreateBookingModal = () => setCreateBookingModal(!createBookingModal);
    const toggleViewBookingModal = () => setViewBookingModal(!viewBookingModal);

    const selectSlotHandler = (booking_id: any) => {
        setBookingId(booking_id);
        toggleViewBookingModal();
    }

    const emptySlotHandler = (slot: any) => {
        setSelectedSlot(slot);
        toggleCreateBookingModal();
    }

    const eventStyle = (event: any) =>  {
        let style = {};
        if(event.booking_type === 'blockout'){
            style = {
                backgroundColor: '#424242',
                borderColor: '#424242',
                color: 'white'
            };
        } else {
            style = {
                backgroundColor: '#1a90ff',
                borderColor: '#1a90ff',
                color: 'white'
            };
        }

        return {
            className: "",
            style: style
        }
    }

    const changeDateHandler = (date: any) => {
        setSlots([]);
        setCalendarDate(date);
    }

    return (
        <Content style={{backgroundColor: 'white', padding: '1rem', margin: 0 }}>
            {calendarSettingsData && (
                <React.Fragment>
                    <BigCalendar
                        selectable={true}
                        onSelectEvent={(event: any) => selectSlotHandler(event.booking._id)}
                        onSelectSlot={(slot: any) => emptySlotHandler(slot)}
                        localizer={localizer}
                        events={slots}
                        startAccessor="start"
                        endAccessor="end"
                        views={["week"]}
                        defaultView="week"
                        style={{ height: '100vh' }}
                        min={set(new Date(), { hours: calendarSettingsData.calendar_settings.start_time, minutes: 0 })}
                        max={set(new Date(), { hours: calendarSettingsData.calendar_settings.finish_time, minutes: 0 })}
                        onNavigate={changeDateHandler}
                        eventPropGetter={eventStyle}
                        components={{week: {header: ({ date }: any) => <CalStaffString date={date}/>}}} 
                    />
                    <CreateTimeBookingModal isOpen={createBookingModal} toggle={toggleCreateBookingModal} selectedSlot={selectedSlot}/> <ViewTimeBookingModal isOpen={viewBookingModal} toggle={toggleViewBookingModal} bookingId={bookingId}/>
                </React.Fragment>
            )}
        </Content>
    );
}
