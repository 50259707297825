import React from 'react';
import { Select } from 'antd';

type Props = {
    selected_booking_type?: string,
    changeBookingTypeHandler: (booking_type: string) => void,
};

const { Option } = Select;

export const BookingTypeSelect = ({selected_booking_type, changeBookingTypeHandler}: Props) => {
    return (
        <Select style={{ width: '100%' }} value={selected_booking_type} onChange={(booking_type: any) => changeBookingTypeHandler(booking_type)}>
            <Option key="slot" value="slot">Slot</Option>
            <Option key="time" value="time">Time</Option>
            <Option key="baby" value="baby">Baby</Option>
        </Select>
    );
}
