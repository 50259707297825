import React from 'react';

type AppContextType = {
    sidebarVisible: boolean
    setSidebarVisible: (visible: boolean) => void
    selectedDate: Date | null;
    setSelectedDate: (date: Date) => void;
    selectedDateString: string;
    setSelectedDateString: (date: string) => void;
}

export const AppContext = React.createContext({} as AppContextType);
