import React, { useState } from 'react';
import { TeamOutlined, RetweetOutlined } from '@ant-design/icons';

import { StaffModal } from '../Modals/StaffModal';

export const StaffSelect = ({staff, slotId, rowId}: any) => {
	const [staffModal, setStaffModal] = useState(false);

    const toggleStaffModal = () => setStaffModal(!staffModal);

    return (
        <>
            {staff ? (
                <h4 style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}><RetweetOutlined onClick={toggleStaffModal}/> {' '} <TeamOutlined/> - {staff.first_name} {staff.last_name}</h4>
            ) : ( 
                <h4 style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}><RetweetOutlined onClick={toggleStaffModal}/> {' '} <TeamOutlined/> - </h4>
            )}
            <StaffModal isOpen={staffModal} toggle={toggleStaffModal} staffId={staff} slotId={slotId} rowId={rowId}/>
        </>
    );
}
