import React, { useState, useContext } from 'react';
import { useQuery, gql } from '@apollo/client';
import { Layout, Button, List, PageHeader, Row, Col } from 'antd';
import { convert24Hour } from '../../tools/time-converter';
import { format } from 'date-fns';
import { ReloadOutlined, TeamOutlined } from '@ant-design/icons';

import './StaffBookingsContainer.css';

import { Datepicker } from '../../components/Datepicker/Datepicker';
import { FormButton } from '../../components/Buttons/FormButton';
import { BookingString } from '../../components/Strings/BookingString';

import { AppContext } from '../../context/AppContext'

const GET_CALENDAR = gql`
	query Calendar($date: String!){
		calendar(date: $date) {
			_id
		}
	}
`;

const STAFF = gql`
    query ($calendar_id: String!) {
        staff_dashboard(calendar_id: $calendar_id)
    }
`;

const STAFF_SLOTS = gql`
    query ($date: String!, $calendar_id: ID!, $staff_id: ID!) {
        staff_slots(date: $date, staff_id: $staff_id, calendar_id: $calendar_id) {
            _id
            time
            service_id {
                name
            }
            staff_id {
                first_name
                last_name
            }
            column_index
        }
    }
`;

const { Content } = Layout;

export const StaffBookingsContainer = () => {
	const { selectedDate } = useContext(AppContext);
    const [selectedStaff, setSelectedStaff] = useState({} as any);

    const { data: calendarData } = useQuery(GET_CALENDAR, {
		variables: {date: selectedDate}
    });

    const calendarId = calendarData?.calendar?._id;

    const { data: staffData } = useQuery(STAFF, {
         variables: {
             calendar_id: calendarId,
         },
         skip: !calendarId
     });

    const { data: staffSlotsData } = useQuery(STAFF_SLOTS, {
         variables: {
             date: selectedDate,
             calendar_id: calendarId,
             staff_id: selectedStaff._id 
         },
         skip: !calendarId || !selectedStaff._id
     });

    const setStaffHandler = (staff: any) => {
        setSelectedStaff(staff);
    }

    return (
        <Content>
            <PageHeader title="Staff Bookings" subTitle={selectedDate && format(selectedDate, 'cccc do MMM')} style={{ backgroundColor: 'white' }} extra={[
                <div key="button" style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <ReloadOutlined key="reload" style={{ fontSize: '1.5rem', margin: '0 1rem' }} onClick={() => window.location.reload(false)}/>
                    <Datepicker key="date-picker"/>
                </div>
            ]}/>
            {staffData && staffData.staff_dashboard.length > 0 ? (
                <Row style={{ padding: '1rem', backgroundColor: 'white', marginTop: '1rem'}}>
                    <Col span={6}>
                        <h2>Staff</h2>
                        <hr />
                        {staffData && (
                            <List itemLayout="horizontal" dataSource={staffData.staff_dashboard} renderItem={(staff: any, index: number)=> (
                                <List.Item key={index} actions={[<Button type="primary" onClick={() => setStaffHandler(staff)}>View</Button>]}>
                                    <List.Item.Meta title={staff.staff_name[0]}/>
                                </List.Item>
                            )}/>
                        )}
                    </Col>
                    <Col span={18} style={{padding: '0 1rem'}}>
                        <h2>Appointments</h2>
                        <hr />
                        {staffSlotsData && 
                            <List itemLayout="vertical" dataSource={staffSlotsData.staff_slots} renderItem={(slot: any, index: number)=> (
                                <List.Item key={index} actions={[
                                    <FormButton key='client-button' type="client" slot={slot} />,
                                    <FormButton key='feedback-button' type="feedback" slot={slot} />,
                                ]}>
                                    <List.Item.Meta
                                        title={`${convert24Hour(slot.time)}, ${slot.service_id.name}, Room: ${slot.column_index}`}
                                        description={<BookingString slot_id={slot._id}/>}/>
                                </List.Item>
                            )}/>
                        }
                    </Col>
                </Row>
            ) : (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '1rem', justifyContent: 'center', backgroundColor: 'white', height: '50vh'}}>
                    <TeamOutlined style={{ fontSize: '4rem' }}/>
                    <h3 style={{ fontSize: '2rem' }}>No Staff Assigned</h3>
                </div>
            )}
        </Content>
    );
    }
