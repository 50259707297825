import React from 'react';

export const ServiceString = ({service}: any) => {

    return (
        <>
            {Object.keys(service).length > 0 ? service.name : 'Services'}
        </>
    );
}
