import React, { useState } from 'react';
import { Layout, PageHeader, Button } from 'antd';

import './TimeCalendarPage.css';

import { TimeCalendar } from '../../components/TimeCalendar/TimeCalendar';
import { BlockoutModal } from '../../components/Modals/BlockoutModal';

const { Content } = Layout;

export const TimeCalendarPage = () => {
    const [blockoutModal, setBlockoutModal] = useState(false);

    const toggleBlockoutModal = () => setBlockoutModal(!blockoutModal);

    return (
        <Content style={{ padding: '1rem' }}>
            <PageHeader title="Time Calendar" style={{ backgroundColor: 'white', marginBottom: '1rem' }} extra={[
                <Button key="blockout" type="primary" onClick={toggleBlockoutModal}>Add Blockout</Button>,
            ]}/>
            <TimeCalendar />
            <BlockoutModal isOpen={blockoutModal} toggle={toggleBlockoutModal}/>
        </Content>
    );
}
