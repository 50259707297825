import React from 'react';
import { Result, Layout, Button } from 'antd';

import './style.css';

const { Content } = Layout;

export const BookingConfirmation = () => {
    const homeButtonHandler = () => {
        window.location.reload();
    }

    return (
        <Content style={{marginTop: '1rem', borderRadius: '1rem', background: 'white'}}>
            <Result
                status="success"
                title="Booking Confirmed"
                subTitle="Your booking has been confirmed, you will recieve a confirmation email shortly"
                extra={[
                    <Button type="primary" size="large" shape="round" key="console" onClick={homeButtonHandler}>
                      Finish 
                    </Button>
            ]}/>
        </Content>
    );
}
