import React from 'react';
import ReactDOM from 'react-dom';

import 'antd/dist/antd.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-square-payment-form/lib/default.css'

import { App } from './App';

import './index.css';


ReactDOM.render(<App />, document.getElementById('root'));
