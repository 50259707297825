import React, { useContext, useState } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Button, Layout, Radio, message } from 'antd';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { format } from 'date-fns';
import { convertFormatted } from '../../tools/time-converter';
import { InfoCircleOutlined } from '@ant-design/icons';

import { StripePayment } from '../Payments/Stripe';
import { SquarePayment } from '../Payments/Square';

import { GET_SETTINGS, GET_STRIPE_SETTINGS_SAFE } from '../../api/Query';

import { BookingContext } from '../../context/BookingContext';

const CREATE_BOOKING = gql`
    mutation ($date: String!, $slot_id: ID, $service_id: ID, $first_name: String!, $last_name: String!, $email: String!, $mobile: Int!, $start_time: String, $marketing: Boolean!, $payment_id: String) {
        create_booking(booking_input: {date: $date, slot_id: $slot_id, service_id: $service_id, first_name: $first_name, last_name: $last_name, email: $email, mobile: $mobile, start_time: $start_time, marketing: $marketing, payment_id: $payment_id}) {
            _id
        }
    }
`;

const { Content } = Layout;

export const Payment = () => {
    const { service, slotId, time, date, formData, setBookingId, nextStep } = useContext(BookingContext);
    const [paymentMethod, setPaymentMethod] = useState('online');
    const [stripePromise, setStripePromise] = useState<any>(null);
    const [paymentOptionVisible, setPaymentOptionVisible] = useState(true);
    const [createBooking] = useMutation(CREATE_BOOKING);

    console.log(date);

    const { data: settingsData } = useQuery(GET_SETTINGS, {
        onCompleted: (data: any) => {
            if(data.settings.stripe_enabled === 0 && data.settings.square_enabled === 0) {
                setPaymentOptionVisible(false);
                setPaymentMethod('instore');
            }
        }
    });

    useQuery(GET_STRIPE_SETTINGS_SAFE, {
        onCompleted: (data: any) => {
            const stripeKey = data.stripe_settings.publishable;
            setStripePromise(loadStripe(stripeKey));
        },
        skip: settingsData.settings.stripe_enabled === 0
    });

    const changePaymentMethod = (e: any) => {
        setPaymentMethod(e.target.value);
    }

    const makeBooking = (payment_id: string) => {
        const formatted_time = new Date(`${format(date, 'yyyy-MM-dd')}T${time}`);
        createBooking({ 
            variables: {
                date: date,
                slot_id: slotId,
                service_id: service._id,
                first_name: formData.first_name,
                last_name: formData.last_name,
                email: formData.email,
                mobile: parseInt(formData.mobile),
                start_time: new Date(formatted_time),
                marketing: formData.marketing,
                payment_id: payment_id
            }
        })
        .then((res: any) => {
            setBookingId(res.data.create_booking._id);
            message.success('Booking Created');
            nextStep();
        });
    }

    return (
        <Content>
            <div>
                <h3 style={{ color: '#a8abaf' }}>Treatment Type</h3>
                <h4>{service.name}</h4>
                <h3 style={{ color: '#a8abaf' }}>Date & Time</h3>
                <h4>{convertFormatted(time)} {format(date, 'cccc do MMM')}</h4>
                <br />
                <hr />
                <span style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <b><h3 style={{ color: '#a8abaf', paddingBottom: 0 }}>Total Amount</h3></b>
                    <b><h3 style={{ paddingBottom: 0 }}>{`$${service.price}`}</h3></b>
                </span>
                <hr />
                <h4>Payment Method</h4>
                {paymentOptionVisible && (
                    <Radio.Group onChange={changePaymentMethod} value={paymentMethod}>
                        <Radio value={'online'}>Online</Radio>
                        <Radio value={'instore'}>Instore</Radio>
                    </Radio.Group>
                )}
                {paymentMethod === 'instore' && (
                    <>
                        <h4><InfoCircleOutlined/> Please pay for your treatment when you arrive at the clinic.</h4>
                        <Button type="primary" size="large" shape="round" block onClick={() => makeBooking('instore-payment')}>Make Booking</Button>
                    </>
                )}
                {paymentMethod === 'online' && (
                    <>
                    {settingsData.settings.stripe_enabled === 1 && (
                        <>
                        {stripePromise !== null &&
                            <Elements stripe={stripePromise}>
                                <StripePayment form_data={formData} amount={service.price} bookingCallback={makeBooking} />
                            </Elements>
                        }
                        </>
                    )}
                    {settingsData.settings.square_enabled === 1 && (
                        <SquarePayment amount={service.price} bookingCallback={makeBooking} />
                    )}
                    </>
                )}
            </div>
        </Content>
    )
}
