import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { Row as AntRow, Col } from 'antd';

import './Row.css';

import { SlotCard } from '../Cards/SlotCard';

const GET_ROW = gql`
    query Row($row_id: ID!){
        row(row_id: $row_id) {
            _id
            time
            slots {
                _id
                calendar_id
                type
                service: service_id {
                    _id
                    name
                }
                time
                staff: staff_id {
                    _id
                    first_name
                    last_name
                }
                available
            }
        }
    }
`;

type Props = {
    _id: string,
};

export const Row = ({ _id }: Props) => {
    const { data: rowData } = useQuery(GET_ROW, {
		variables: {row_id: _id}
    });

    return (
        <AntRow gutter={[16, 16]} style={{ marginBottom: '1rem' }}>
            {rowData && rowData.row.slots.map((slot: any, index: number) => {
                return (
                    <Col flex="1" key={index} span={5}>
                        <SlotCard row_id={_id} {...slot} />
                    </Col>
                );
            })}
        </AntRow>
    );
}
