import React, { useState } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Button, Tooltip, Card, message, Popconfirm } from 'antd';
import { StopOutlined, DeleteOutlined } from '@ant-design/icons';
import { convert24Hour } from '../../tools/time-converter';

import { ServiceSelect } from '../Selects/ServiceSelect';
import { StaffSelect } from '../Selects/StaffSelect';
import { BookingSelect } from '../Selects/BookingSelect';
import { BookingButton } from '../Buttons/BookingButton';

import { GET_BOOKING, DELETE_SLOT, GET_ROW, CHANGE_SLOT_SERVICE } from '../../api/Query';

const UPDATE_SLOT_TYPE = gql`
    mutation ($slot_id: ID!, $slot_type: String!) {
        update_slot_type(slot_id: $slot_id, slot_type: $slot_type) {
            _id
        }
    }
`;

type Props = {
    _id: string,
    type: string,
    time: number,
    service: any,
    available: boolean,
    staff: any,
    row_id: string
}

export const SlotCard = ({_id, type, time, service, available, staff, row_id}: Props) => {
    const [bookingId, setBookingId] = useState('');
	const [deleteSlot] = useMutation(DELETE_SLOT);
	const [updateSlotType] = useMutation(UPDATE_SLOT_TYPE);
	const [changeSlotService] = useMutation(CHANGE_SLOT_SERVICE);

    useQuery(GET_BOOKING, {
		variables: {slot_id: _id},
        skip: available,
        onCompleted: (data: any) => {
            if(data.booking) {
                setBookingId(data.booking._id);
            }
        }
    });

    const changeServiceHandler = (new_service: any) => {
        changeSlotService({ 
			variables: {
				slot_id: _id,
                service_id: new_service 
			},
			refetchQueries: [
				{ query: GET_ROW, variables: { row_id: row_id} }
			]
		})
		.then(() => {
			message.success('Slot Service Changed');
		});
    }

    const deleteSlotHandler = () => {
		deleteSlot({ 
			variables: {
				slot_id: _id,
			},
			refetchQueries: [
				{ query: GET_ROW, variables: { row_id: row_id} }
			]
		})
		.then(() => {
			message.error('Slot Deleted');
		});
    }

    const blockoutSlotHandler = () => {
		updateSlotType({ 
			variables: {
				slot_id: _id,
                slot_type: 'blockout'
			},
			refetchQueries: [
				{ query: GET_ROW, variables: { row_id: row_id} }
			]
		})
		.then(() => {
			message.success('Slot Blocked Out');
            window.location.reload();
		});
    }

    const removeBlockoutHandler = () => {
		updateSlotType({ 
			variables: {
				slot_id: _id,
                slot_type: 'slot'
			},
			refetchQueries: [
				{ query: GET_ROW, variables: { row_id: row_id} }
			]
		})
		.then(() => {
			message.success('Slot Blockout Removed');
            window.location.reload();
		});
    }

    return (
        <>
        {type !== 'blockout' ? (
            <Card title={convert24Hour(time)} style={{padding: 0, height: '100%' }} 
                extra={ service && [
                    <Tooltip key="blockout-slot" title="Blockout Slot">
                        <StopOutlined key="blockout-slot" onClick={blockoutSlotHandler} style={{ marginRight: '0.5rem' }} />
                    </Tooltip>,
                    <Popconfirm key="delete-slot" title="Are you sure to delete this slot?" onConfirm={deleteSlotHandler} okText="Yes" cancelText="No">
                        <DeleteOutlined />
                    </Popconfirm>
                ]} 
                actions={ service && [
                    <BookingButton key={_id} available={available} slotId={_id} rowId={row_id} bookingId={bookingId}/>
                ]}>
                {service ? (
                    <ServiceSelect selected_service={service._id} changeServiceHandler={changeServiceHandler} />
                ) : (
                    <h2>Staff</h2>
                )}
                <hr/>
                <StaffSelect staff={staff} slotId={_id} rowId={row_id}/>
                {service && (
                    <BookingSelect available={available} slotId={_id} />
                )}
            </Card>
        ) : (
            <Card title={convert24Hour(time)} style={{padding: 0, height: '100%' }} actions={[
                <div style={{ padding: '1rem' }}>
                    <Button block danger type="primary" onClick={removeBlockoutHandler}>Remove Blockout</Button>
                </div>
            ]}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'row', alignItems: 'center' }}>
                    <h1>Blockout</h1>
                </div>
            </Card>
        )}
        </>
    );
}
