import React, { useState } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Switch, message, Col, Form, Input, Button } from 'antd';
import { MailOutlined, SettingOutlined } from '@ant-design/icons';

import { GET_EMAIL_SETTINGS } from '../../api/Query';

const UPDATE_EMAIL = gql`
    mutation ($settings_id: String!, $from_address: String!) {
        update_email_settings(settings_id: $settings_id, email_settings_input: {from_address: $from_address}) {
            _id
        }
    }
`;

type Props = {
    settings: any,
    updateIntegrationHandler: (name: string, value: number) => void
};

export const EmailIntegration = ({settings, updateIntegrationHandler}: Props) => {
    const [showSettings, setShowSettings] = useState(false);
    const { data: emailSettingsData } = useQuery(GET_EMAIL_SETTINGS);
    const [updateEmail] = useMutation(UPDATE_EMAIL);

    const updateEmailHandler = (form_data: any) => {
        form_data.settings_id = emailSettingsData.email_settings._id;
        updateEmail({
            variables: {
                ...form_data
            },
            refetchQueries: [
                { query: GET_EMAIL_SETTINGS }
            ],
        }).then(() => {
            message.success('Email Settings Updated');
            setShowSettings(false);
        });
    }

    return (
        <Col flex="1" span={8}>
            <div className="integrations-item">
                {showSettings ? (
                    <>
                    {settings.email_enabled === 1 && emailSettingsData && (
                        <Form layout="vertical" onFinish={updateEmailHandler} initialValues={emailSettingsData.email_settings}>
                            <Form.Item name="from_address" label="From Address">
                                <Input placeholder="From Address" />
                            </Form.Item>
                            <Button type="primary" block htmlType="submit">Save</Button>
                        </Form>
                    )}
                    </>
                ) : (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <MailOutlined style={{ fontSize: '3rem' }}/>
                        <h1>Email</h1>
                        <Switch defaultChecked={settings.email_enabled} onChange={(value: any) => updateIntegrationHandler('email_enabled', value)}/>
                        <hr />
                        {settings.email_enabled === 1 && 
                            <SettingOutlined style={{ cursor: 'pointer', fontSize: '1rem' }} onClick={() => setShowSettings(!showSettings)}/>
                        }
                    </div>
                )}
            </div>
        </Col>
    );

}
