import React, { useEffect, useState } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { MastercardLogo, VisaLogo } from '../Logos/Logos';

import { GET_SQUARE_SETTINGS } from '../../api/Query';

const SQUARE_PAYMENT = gql`
    mutation ($nonce: String!, $amount: String!) {
        square_payment(nonce: $nonce, amount: $amount) 
    }
`;

declare const window: any;

export const SquarePayment = ({amount, bookingCallback}: any) => {
    const Square = window.Square;
    let card: any = {};
    const { data: squareSettingsData } = useQuery(GET_SQUARE_SETTINGS);
    const [correctConfig, setCorrectConfig] = useState(false);
    const [scriptLoaded, setScriptLoaded] = useState(false);
    const [squareCard, setSquareCard] = useState<any>({});
    const [squarePayment] = useMutation(SQUARE_PAYMENT);
    const [processing, setProcessing] = useState(false);

    useEffect(() => {
        const scriptTag = document.createElement('script');
        if(squareSettingsData && squareSettingsData.square_settings.production === 1) {
            scriptTag.src = 'https://web.squarecdn.com/v1/square.js';
            scriptTag.addEventListener('load', () => setScriptLoaded(true));
            document.body.appendChild(scriptTag);
        }
        if(squareSettingsData && squareSettingsData.square_settings.production === 0){
            scriptTag.src = 'https://sandbox.web.squarecdn.com/v1/square.js';
            scriptTag.addEventListener('load', () => setScriptLoaded(true));
            document.body.appendChild(scriptTag);
        }
    }, [squareSettingsData]);

    useEffect(() => {
        if (!scriptLoaded) return;
        const loadPayment = async () => {
            if(squareSettingsData && squareSettingsData.square_settings.app_id) {
                setCorrectConfig(true);
                const payments = Square.payments(squareSettingsData.square_settings.app_id, squareSettingsData.square_settings.location_id); 
                card = await payments.card();
                card.attach('#card-container');
                setSquareCard(card);
            }
        };
        loadPayment();
    }, [scriptLoaded]);

    const makePayment = async () => {
        const square_nonce = await squareCard.tokenize();
        if(square_nonce.status === 'OK') {
            setProcessing(true);
            squarePayment({
                variables: {
                    nonce: square_nonce.token,
                    amount: `${amount * 100}`
                }
            }).then((res: any) => {
                setProcessing(false);
                bookingCallback(res.data.square_payment);
            });
        }
    }

    return (
        <>
            {correctConfig ? (
                <>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <h4><InfoCircleOutlined/> No cancellations or changes allowed within 24 hours of the appointment.</h4>
                        <MastercardLogo />
                        <VisaLogo />
                    </div>
                    <div id="card-container"></div>
                    <Button type="primary" block size="large" shape="round" disabled={processing} onClick={makePayment}>Proceed to Pay</Button>
                </>
            ) : (
                <h4><InfoCircleOutlined/> Square payments are not configured correctly. Please contact the administrator.</h4>
            )}
        </>
    );
}
