import React from 'react';
import { useMutation } from '@apollo/client';
import { Form, Layout, Button, Input, message } from 'antd';

import './StaffForm.css';

import { GET_ALL_STAFF, CREATE_STAFF } from '../../../api/Query';

type Props = {
    setCreateStaff: (createStaff: boolean) => void
}

const { Content } = Layout;

export const StaffForm = ({setCreateStaff}: Props) => {
    const [createStaff] = useMutation(CREATE_STAFF);

	const onSubmit = (form_data: any) => {
		createStaff({ 
			variables: {
				first_name: form_data.first_name,
				last_name: form_data.last_name,
			},
			refetchQueries: [
				{ query: GET_ALL_STAFF },
			]
		})
		.then(() => {
            message.success('Staff Created');
            setCreateStaff(false);
		});
	}

    return (
        <Content className="staff-form">
            <Form layout="vertical" onFinish={onSubmit}>
                <Form.Item name="first_name" label="First Name" rules={[{ required: true, message: 'Please provide a first name'}]}>
                    <Input placeholder="First Name"/>
                </Form.Item>
                <Form.Item name="last_name" label="Last Name" rules={[{ required: true, message: 'Please provide a last name'}]}>
                    <Input placeholder="Last Name"/>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">Create Staff</Button>
                </Form.Item>
            </Form>
        </Content>
    );
}
