import React, { useState } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Switch, message, Col, Form, Input, Button } from 'antd';
import { GlobalOutlined, SettingOutlined } from '@ant-design/icons';

import { GET_MAILCHIMP_SETTINGS } from '../../api/Query';

const UPDATE_MAILCHIMP = gql`
    mutation ($settings_id: String!, $access_token: String!, $list_id: String!, $server: String!) {
        update_mailchimp_settings(settings_id: $settings_id, mailchimp_settings_input: {access_token: $access_token, list_id: $list_id, server: $server}) {
            _id
        }
    }
`;

type Props = {
    settings: any,
    updateIntegrationHandler: (name: string, value: number) => void
};

export const MailchimpIntegration = ({settings, updateIntegrationHandler}: Props) => {
    const [showSettings, setShowSettings] = useState(false);
    const { data: mailchimpSettingsData } = useQuery(GET_MAILCHIMP_SETTINGS);
    const [updateMailchimp] = useMutation(UPDATE_MAILCHIMP);

    const updateMailchimpHandler = (form_data: any) => {
        form_data.settings_id = mailchimpSettingsData.mailchimp_settings._id;
        updateMailchimp({
            variables: {
                ...form_data
            },
            refetchQueries: [
                { query: GET_MAILCHIMP_SETTINGS }
            ],
        }).then(() => {
            message.success('Mailchimp Settings Updated');
            setShowSettings(false);
        });
    }

    return (
        <Col flex="1" span={8}>
            <div className="integrations-item">
                {showSettings ? (
                    <>
                        {settings.mailchimp_enabled === 1 && mailchimpSettingsData && (
                            <Form layout="vertical" onFinish={updateMailchimpHandler} initialValues={mailchimpSettingsData.mailchimp_settings}>
                                <Form.Item name="access_token" label="Access Token">
                                    <Input placeholder="Access Token" />
                                </Form.Item>
                                <Form.Item name="list_id" label="List ID">
                                    <Input placeholder="List ID" />
                                </Form.Item>
                                <Form.Item name="server" label="Server">
                                    <Input placeholder="Server" />
                                </Form.Item>
                                <Button type="primary" block htmlType="submit">Save</Button>
                            </Form>
                        )}
                    </>
                ) : (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <GlobalOutlined style={{ fontSize: '3rem' }}/>
                        <h1>Mailchimp</h1>
                        <Switch defaultChecked={settings.mailchimp_enabled} onChange={(value: any) => updateIntegrationHandler('mailchimp_enabled', value)}/>
                        <hr />
                        {settings.mailchimp_enabled === 1 && 
                            <SettingOutlined style={{ cursor: 'pointer', fontSize: '1rem' }} onClick={() => setShowSettings(!showSettings)}/>
                        }
                    </div>
                )}
            </div>
        </Col>
    );
}
