import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { Modal, Form, Input, message } from 'antd';
import { format } from 'date-fns';
import { isEmpty } from 'lodash';

import { ServiceSelect } from '../Selects/ServiceSelect';

const CREATE_BOOKING = gql`
    mutation ($date: String!, $service_id: ID!, $first_name: String!, $last_name: String!, $email: String!, $mobile: Int!, $start_time: String!) {
        create_time_booking(booking_input: {date: $date, service_id: $service_id, first_name: $first_name, last_name: $last_name, email: $email, mobile: $mobile, start_time: $start_time}) {
            _id
        }
    }
`;


export const CreateTimeBookingModal = ({isOpen, toggle, selectedSlot}: any) => {
    const [createBooking] = useMutation(CREATE_BOOKING);
    const [selectedService, setSelectedService] = useState('');
    const [form] = Form.useForm();

    const changeServiceHandler = (service: any) => {
        setSelectedService(service);
    }

	const onSubmit = (form_data: any) => {
		createBooking({ 
			variables: {
                date: format(selectedSlot.start, 'yyyy-MM-dd'),
                service_id: selectedService,
				first_name: form_data.first_name,
				last_name: form_data.last_name,
				email: form_data.email,
				mobile: parseInt(form_data.mobile),
                start_time: selectedSlot.start,
			}
		})
		.then(() => {
            toggle();
            window.location.reload();
            message.success('Booking Created');
        });
	}

    return (
        <Modal title={!isEmpty(selectedSlot) && `${format(selectedSlot.start, 'HH:mm')}`} visible={isOpen} okText="Create Booking" onCancel={toggle} onOk={() => {
            form.validateFields().then((form_data: any) => { 
                form.resetFields();
                onSubmit(form_data);
            }).catch((info: any) => {
                console.log('Validate Failed:', info);
            });
        }}>
            <Form form={form} layout="vertical" onFinish={onSubmit}>
                <Form.Item name="first_name" label="First Name" rules={[{ required: true, message: 'Please provide a first name'}]}>
                    <Input placeholder="First Name"/>
                </Form.Item>
                <Form.Item name="last_name" label="Last Name" rules={[{ required: true, message: 'Please provide a last name'}]}>
                    <Input placeholder="Last Name"/>
                </Form.Item>
                <Form.Item name="mobile" label="Mobile" rules={[{ required: true, message: 'Please provide a mobile'}]}>
                    <Input placeholder="Mobile"/>
                </Form.Item>
                <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Please provide a email'}]}>
                    <Input placeholder="Email"/>
                </Form.Item>
                <Form.Item name="service" label="Service">
                    <ServiceSelect changeServiceHandler={changeServiceHandler}/>
                </Form.Item>
            </Form>
      </Modal>
    )
}

