import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { Calendar as AntCalendar } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import moment from 'moment';
import { subDays } from 'date-fns';

import { BookingContext } from '../../../context/BookingContext';

import { GET_BLOCKOUT_SETTINGS } from '../../../api/Query';

export const Calendar = () => {
    const { date, setDate, setTodayBooking, setService, setTime } = useContext(BookingContext);
    const { data: blockoutData } = useQuery(GET_BLOCKOUT_SETTINGS);

    const changeDateHandler = (date: any) => {
        const currentDate = new Date();
        setService({});
        setTime('');
        setDate(date._d);
        if(date._d.getDate() === currentDate.getDate()) {
            setTodayBooking(true);
        } else {
            setTodayBooking(false);
        }
    }

    return (
        <>
            <h4><b><CalendarOutlined /> Please Select A Date</b></h4>
            {blockoutData && (
                <AntCalendar 
                    defaultValue={moment(date)}
                    fullscreen={false} 
                    onPanelChange={changeDateHandler}
                    onSelect={changeDateHandler}
                    disabledDate={(current: any) => {
                        return current._d < subDays(new Date(), 1) || blockoutData.blockout_settings.days.find((day: any) => 
                            day === current.format("dddd"));
                    }}
                />
            )}
        </>
    );

}
