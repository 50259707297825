import React, { useState, useContext } from 'react';
import { Layout, Button, PageHeader, message } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import { format } from 'date-fns';

import './SlotCalendarPage.css';

import { AppContext } from '../../context/AppContext';
import { CalendarContext } from '../../context/CalendarContext';

import { Row } from '../../components/Row/Row';
import { CreateCalendarModal } from '../../components/Modals/CreateCalendarModal/CreateCalendarModal';
import { CreateRowModal } from '../../components/Modals/CreateRowModal/CreateRowModal';
import { CalendarButton } from '../../components/Buttons/CalendarButton';
import { Datepicker } from '../../components/Datepicker/Datepicker';
import { ViewBookingModal } from '../../components/Modals/ViewBookingModal';
import { CreateBookingModal } from '../../components/Modals/CreateBookingModal';

import { GET_CALENDAR, DELETE_CALENDAR } from '../../api/Query';

const { Content } = Layout;

export const SlotCalendarPage = () => {
    const { selectedDate } = useContext(AppContext);
    const [bookingId, setBookingId] = useState('');
    const [slotId, setSlotId] = useState('');
    const [rowId, setRowId] = useState('');
	const [createCalendarModal, setCreateCalendarModal] = useState(false);
	const [createRowModal, setCreateRowModal] = useState(false);
	const [viewBookingModal, setViewBookingModal] = useState(false);
	const [createBookingModal, setCreateBookingModal] = useState(false);
	const [deleteCalendar] = useMutation(DELETE_CALENDAR); 

    let calendarLoaded = false;
    let rows = [];

    const { data: calendarData } = useQuery(GET_CALENDAR, {
		variables: {date: selectedDate}
    });

	const toggleCreateCalendarModal = () => setCreateCalendarModal(!createCalendarModal); 
	const toggleCreateRowModal = () => setCreateRowModal(!createRowModal);
	const toggleViewBookingModal = () => setViewBookingModal(!viewBookingModal);
	const toggleCreateBookingModal = () => setCreateBookingModal(!createBookingModal);

    if(calendarData && calendarData.calendar) {
        rows = calendarData.calendar.rows.slice().sort((a: any, b: any) => (parseInt(a.time) > parseInt(b.time)) ? 1 : ((parseInt(b.time) > parseInt(a.time)) ? -1 : 0))
        if(calendarData.calendar !== null) {
            calendarLoaded = true;
        } else {
            calendarLoaded = false;
        }
    }

    const deleteCalendarHandler = () => {
		deleteCalendar({ 
			variables: {
				calendar_id: calendarData.calendar._id
			},
			refetchQueries: [
                { query: GET_CALENDAR, variables: { date: selectedDate} }
			]
		})
		.then(() => {
			message.error('Calendar Deleted');
		});
    }

    return (
        <Content style={{ padding: '1rem' }}>
            <CalendarContext.Provider value={{ slotId, setSlotId, rowId, setRowId, bookingId, setBookingId, viewBookingModal, setViewBookingModal, createBookingModal, setCreateBookingModal }}>
                <PageHeader title="Slot Calendar" subTitle={selectedDate && format(selectedDate, 'cccc do MMM')} style={{ backgroundColor: 'white', marginBottom: '1rem' }} extra={[
                    <div key="button" style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <Datepicker />
                        <Button type="primary" key="create_row" onClick={toggleCreateRowModal} style={{margin: '0 0.5rem'}}>Create Row</Button>
                        <CalendarButton key="calendar_button" calendarLoaded={calendarLoaded} createCalendarHandler={() => toggleCreateCalendarModal()} deleteCalendarHandler={deleteCalendarHandler}/>
                    </div>
                ]}/>
                {rows && rows.map((row: any, index: number) => {
                    return (
                        <Row key={index} {...row} />
                    );
                })}
                <CreateCalendarModal isOpen={createCalendarModal} toggle={toggleCreateCalendarModal} />
                {calendarData && calendarData.calendar &&
                    <CreateRowModal isOpen={createRowModal} toggle={toggleCreateRowModal}  calendarId={calendarData.calendar._id}/>
                }
                <ViewBookingModal isOpen={viewBookingModal} toggle={toggleViewBookingModal} bookingId={bookingId} />
                <CreateBookingModal isOpen={createBookingModal} toggle={toggleCreateBookingModal} slotId={slotId} rowId={rowId} />
            </CalendarContext.Provider>
        </Content>
    );
}

