import React, { useContext, useState } from 'react';
import { Row, Col, Layout } from 'antd';
import { useMediaQuery } from 'react-responsive';

import './style.css';

import { BookingContext } from '../../context/BookingContext';

import { Form } from './components/Form';
import { Payment } from '../../components/Payments/Payment';

const { Content } = Layout;

export const BookingForm = () => {
    const { formValid } = useContext(BookingContext);
    const [mobileStep, setMobileStep] = useState(0);
    const isMobile = useMediaQuery({query: '(max-width: 767px)'});

    const mobileStepHandler = () => {
        console.log('called');
    }

    return (
        <Content style={{marginTop: '1rem' }}>
            <Row gutter={[16, 16]}>
                {isMobile ? (
                    <>
                        {mobileStep === 0 && (
                            <Col span={24}>
                                <Form callback={() => setMobileStep(1)} />
                            </Col>
                        )}
                        {mobileStep === 1 && (
                            <Col lg={10} md={10} xs={24}>
                                <div className="rounded-item">
                                    {formValid && (
                                        <>
                                            <h4><b>Review & Payment</b></h4>
                                            <Payment />
                                        </>
                                    )}
                                </div>
                            </Col>
                        )}
                    </>
                ) : (
                    <>
                        <Col lg={14} md={14} xs={24}>
                            <Form /> 
                        </Col>
                        <Col lg={10} md={10} xs={24}>
                            <div className="rounded-item">
                                {formValid ? (
                                    <>
                                        <h4><b>Review & Payment</b></h4>
                                        <Payment />
                                    </>
                                ) : (
                                    <h4><b>Please Fill Out Your Information</b></h4>
                                )}
                            </div>
                        </Col>
                    </> 
                )}
            </Row>
        </Content>
    )
}

