import React from 'react';
import { useQuery } from '@apollo/client';
import { Select } from 'antd';

import { GET_SERVICES } from '../../api/Query';

type Props = {
    selected_service?: string,
    changeServiceHandler: (service_id: string) => void,
};

const { Option } = Select;

export const ServiceSelect = ({selected_service, changeServiceHandler}: Props) => {
	const { data: servicesData } = useQuery(GET_SERVICES); 

    return (
        <Select placeholder="Select a service" style={{ width: '100%' }} value={selected_service} onChange={(service: any) => changeServiceHandler(service)}>
            {servicesData && servicesData.services.map((service: any, index: number) => {
                return (
                    <Option key={index} value={service._id}>{service.name} (Price: ${service.price} Duration: {service.duration})</Option>
                )
            })}
        </Select>
    );
}
