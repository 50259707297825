import React, { useState, useEffect } from 'react';
import { gql, useMutation } from '@apollo/client';
import { InfoCircleOutlined } from '@ant-design/icons';
import { MastercardLogo, VisaLogo } from '../Logos/Logos';
import { PaymentRequestButtonElement, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Button } from 'antd';

const PAYMENT_INTENT = gql`
    mutation ($amount: String!) {
        stripe_payment_intent(amount: $amount) 
    }
`;

export const StripePayment = ({ form_data, amount, bookingCallback }: any) => {
    const [getClientSecret] = useMutation(PAYMENT_INTENT);
    const [processing, setProcessing] = useState(false);
    const [paymentRequest, setPaymentRequest] = useState(null as any);

    const elements = useElements();
    const stripe = useStripe();

    useEffect(() => {
        if(stripe) {
            const pr = stripe.paymentRequest({
                country: 'AU',
                currency: 'aud',
                total: {
                  label: 'Demo total',
                  amount: amount * 100
                },
                requestPayerName: true,
                requestPayerEmail: true,
            });

            pr.canMakePayment().then(result => {
                if (result) {
                  setPaymentRequest(pr);
                }
            });
        }
    }, [stripe, amount]);

    if (!stripe || !elements) {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', padding: '0 1', alignItems: 'center', justifyContent: 'center' }}>
                <h3>Stripe Error</h3>
                <h4>Please contact your administrator</h4>
            </div>
        );
    }

    const makePayment = () => {
        setProcessing(true);
        const billing_details = {
            name: `${form_data.first_name} ${form_data.last_name}`,
            email: `${form_data.email}`,
        };
        getClientSecret({
            variables: {amount: `${amount * 100}`}
        }).then(async (res: any) => {
            const client_secret = res.data.stripe_payment_intent;
            const cardElement = elements?.getElement(CardElement);

            const paymentMethod = await stripe?.createPaymentMethod({
                type: 'card',
                card: cardElement!,
                billing_details: billing_details,
            });

            await stripe?.confirmCardPayment(client_secret, {
                payment_method: paymentMethod?.paymentMethod?.id,
            }).then((result: any) => {
                if(result.error) {
                    setProcessing(false);
                } else {
                    bookingCallback(result.paymentIntent.id);
                    setProcessing(false);
                }
            });
        });
    }

    const cardElementOptions = {
        style: {
            base: {
                fontSize: '20px',
                color: 'black',
            },
            invalid: {
                color: 'red'
            },
        },
        hidePostalCode: true
    };

    return (
        <>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <h4><InfoCircleOutlined/> No cancellations or changes allowed within 24 hours of the appointment.</h4>
            </div>
                <MastercardLogo />
                <VisaLogo />
            <div style={{border: '0.1rem solid #d9d9d9', borderRadius: '0.25rem', padding: '1rem'}}>
                <CardElement options={cardElementOptions} />
            </div>
            <br/>
            {paymentRequest && (
                <PaymentRequestButtonElement options={{paymentRequest}} />
            )}
            <Button type="primary" block size="large" shape="round" disabled={processing} onClick={makePayment}>Proceed to Pay</Button>
        </>
    );
}
