import React, { useContext, useEffect } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { useParams, useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { Row, Col, Form, message, Button, Input, Radio} from 'antd';

import './FeedbackForm.css';

import { GET_BOOKING, CREATE_FEEDBACK_FORM } from '../../../api/Query';

import { AppContext } from '../../../context/AppContext';

const GET_SLOT = gql`
    query GetSlot($slot_id: ID!){
        slot (slot_id: $slot_id) {
            _id
            time
            service_id {
                _id
            }
            staff_id {
                _id
            }
        }
    }
`;

const GET_STAFF_MEMBER = gql`
    query ($staff_id: ID!){
        staff_member (staff_id: $staff_id) {
            _id
            first_name
            last_name
        }
    }
`;

const { TextArea } = Input;

export const FeedbackForm = () => {
    let { slot_id } = useParams<{ slot_id: string }>();
    const { setSidebarVisible } = useContext(AppContext);
    const [createFeedbackForm] = useMutation(CREATE_FEEDBACK_FORM);
    let history = useHistory();

    useEffect(() => {
        setSidebarVisible(false);
    }, [setSidebarVisible]);

    const { data: bookingData } = useQuery(GET_BOOKING, {
        variables: {slot_id: slot_id}
    });

    const { data: slotData } = useQuery(GET_SLOT, {
        variables: {slot_id: slot_id}
    });

    const staff_id = slotData?.slot?.staff_id._id;

    const { data: staffData } = useQuery(GET_STAFF_MEMBER, {
        variables: {staff_id: staff_id},
        skip: !staff_id
    });

	const onSubmit = (form_data: any) => {
        const student_name = `${staffData.staff_member.first_name} ${staffData.staff_member.last_name}`;
        form_data.date = format(new Date(), 'cccc do MMM');
        form_data.student_name = student_name;
        createFeedbackForm({ 
			variables: {
				student_name: student_name,
                date: format(new Date(), 'yyyy-MM-dd'),
                time:  slotData.slot.time,
				form_data: JSON.stringify(form_data),
                booking_id: bookingData.booking._id 
			}
		})
		.then(() => {
            setSidebarVisible(true);
            message.success('Feedback Form Saved');
            history.push('/dashboard/staff-bookings');
        });
	}

    return (
        <div className="feedback-form-container">
            {staffData && 
            <Form layout="vertical" onFinish={onSubmit} >
                <Row gutter={12}>
                    <Col span={12}>
                        <Form.Item name="date" label="Date"> 
                            <Input defaultValue={format(new Date(), 'dd-MM-yyyy')} disabled/> 
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="student_name" label="Student Name"> 
                            <Input defaultValue={`${staffData.staff_member.first_name} ${staffData.staff_member.last_name}`} disabled/> 
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item name="client_name" label="Client Name (optional)">
                            <Input placeholder="Client Name"/>
                        </Form.Item>
                    </Col>
                </Row>
                <h4>(1 = Poor,  5 = Excellent)</h4>
                <Row>
                    <Col span={24}>
                        <Form.Item name="welcomed&comfortable" label="Did you feel welcomed and comfortable by the student?">
                            <Radio.Group>
                                <Radio value={1}>1</Radio>
                                <Radio value={2}>2</Radio>
                                <Radio value={3}>3</Radio>
                                <Radio value={4}>4</Radio>
                                <Radio value={5}>5</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item name="washed_hands" label="Did the student advise before the treatment they had washed their hands?">
                            <Radio.Group>
                                <Radio value={1}>1</Radio>
                                <Radio value={2}>2</Radio>
                                <Radio value={3}>3</Radio>
                                <Radio value={4}>4</Radio>
                                <Radio value={5}>5</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item name="listened&understood" label="Did you feel the student listened to you and understood your expectations of the treatment?">
                            <Radio.Group>
                                <Radio value={1}>1</Radio>
                                <Radio value={2}>2</Radio>
                                <Radio value={3}>3</Radio>
                                <Radio value={4}>4</Radio>
                                <Radio value={5}>5</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item name="explain_clearly" label="Did your student explain clearly what the treatment would involve?">
                            <Radio.Group>
                                <Radio value={1}>1</Radio>
                                <Radio value={2}>2</Radio>
                                <Radio value={3}>3</Radio>
                                <Radio value={4}>4</Radio>
                                <Radio value={5}>5</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item name="understand_everything" label="Did you understand everything the student asked you to do and why?">
                            <Radio.Group>
                                <Radio value={1}>1</Radio>
                                <Radio value={2}>2</Radio>
                                <Radio value={3}>3</Radio>
                                <Radio value={4}>4</Radio>
                                <Radio value={5}>5</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item name="clear_steps" label="Do you have clear advice about which steps to now take to improve your health?">
                            <Radio.Group>
                                <Radio value={1}>1</Radio>
                                <Radio value={2}>2</Radio>
                                <Radio value={3}>3</Radio>
                                <Radio value={4}>4</Radio>
                                <Radio value={5}>5</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item name="level_comfort" label="Did your student check your level of comfort throught and make changes accordingly to your feedback?">
                            <Radio.Group>
                                <Radio value={1}>1</Radio>
                                <Radio value={2}>2</Radio>
                                <Radio value={3}>3</Radio>
                                <Radio value={4}>4</Radio>
                                <Radio value={5}>5</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item name="questions_clarified" label="Were your questions clarified and appropiate information provided?">
                            <Radio.Group>
                                <Radio value={1}>1</Radio>
                                <Radio value={2}>2</Radio>
                                <Radio value={3}>3</Radio>
                                <Radio value={4}>4</Radio>
                                <Radio value={5}>5</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item name="referral" label="Where applicable, were you referred to another practioner to help your condition?">
                            <Radio.Group>
                                <Radio value='N/A'>N/A</Radio>
                                <Radio value='yes'>Yes</Radio>
                                <Radio value='no'>No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item name="offer_help" label="Did the student advise you it was the end of the session and offer to help you get off the table?">
                            <Radio.Group>
                                <Radio value={1}>1</Radio>
                                <Radio value={2}>2</Radio>
                                <Radio value={3}>3</Radio>
                                <Radio value={4}>4</Radio>
                                <Radio value={5}>5</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item name="facilities" label="Were adequate facilities provided including water, toilet, private consulation area, massage table and clean line?">
                            <Radio.Group>
                                <Radio value={1}>1</Radio>
                                <Radio value={2}>2</Radio>
                                <Radio value={3}>3</Radio>
                                <Radio value={4}>4</Radio>
                                <Radio value={5}>5</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item name="wellbeing" label="Do you feel you achieved your goal in terms of physical/emotional/energetic wellbeing?">
                            <Radio.Group>
                                <Radio value='N/A'>N/A</Radio>
                                <Radio value={1}>1</Radio>
                                <Radio value={2}>2</Radio>
                                <Radio value={3}>3</Radio>
                                <Radio value={4}>4</Radio>
                                <Radio value={5}>5</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item name="other_therapies" label="Were you advised of other available therapies at student clinic?">
                            <Radio.Group>
                                <Radio value='yes'>Yes</Radio>
                                <Radio value='no'>No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item name="comments" label="Any other comments?"> 
                            <TextArea rows={4} />
                        </Form.Item>
                    </Col>
                </Row>
                <Button type="primary" block htmlType="submit">
                    Save 
                </Button>
            </Form>
            }
        </div>
    );
}
