import React, { useContext, useState } from 'react';
import { Button, Layout, Row, Col } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';
import { CalendarOutlined, DownOutlined } from '@ant-design/icons';

import './style.css';

import { SlotDates } from './components/SlotDates';
import { SlotTimes } from './components/SlotTimes';
import { BookingContext } from '../../context/BookingContext';

const { Content } = Layout;

export const SlotBooking = () => {
    const { calendarId, time, date, nextStep } = useContext(BookingContext);
    const [loading, setLoading] = useState(false);
    const [showCalendar, setShowCalendar] = useState(true);
    const [showTimes, setShowTimes] = useState(false);
    const isMobile = useMediaQuery({query: '(max-width: 767px)'});

    const calendarHandler = () => {
        setShowCalendar(false);
        setShowTimes(true);
    }

    return (
        <Content style={{ marginTop: '1rem' }}>
                {loading ? (
                    <div className="rounded-item" style={{ marginTop: '1rem', height: '400px', alignItems: 'center', justifyContent: 'center'}} >
                        <SyncOutlined style={{ fontSize: '4rem', color: '#1a90ff' }} spin/>
                    </div>
                ) : (
                    <> 
                    {isMobile ? (
                        <Row gutter={[16, 16]}> 
                            <Col span={24}>
                                <div style={{ background: 'white', padding: '1rem', borderRadius: '1rem', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <h2><CalendarOutlined /> Date</h2>
                                    <DownOutlined onClick={() => setShowCalendar(!showCalendar)}/>
                                </div>
                            </Col>
                            {showCalendar && (
                                <Col span={24}>
                                    <div className="rounded-item">
                                        <SlotDates />
                                        <hr />
                                        <Button type="primary" block size="large" disabled={!calendarId} style={{ borderRadius: '1rem' }} onClick={calendarHandler}>
                                            Select
                                        </Button>
                                    </div>
                                </Col>
                            )}
                            {showTimes && (
                                <Col span={24}>
                                    <div className="rounded-item">
                                        <SlotTimes />
                                        <hr />
                                        <Button disabled={!time} style={{ marginTop: '1rem' }} size="large" shape="round" type="primary" block onClick={nextStep}>
                                            Continue
                                        </Button>
                                    </div>
                                </Col>
                            )}
                        </Row>
                    ) : (
                        <Row gutter={[16, 16]}>
                            <Col lg={12} md={12}>
                                <div className="rounded-item">
                                    <SlotDates />
                                </div>
                            </Col>
                            <Col lg={12} md={12}>
                                <div className="rounded-item">
                                    <SlotTimes />
                                </div>
                            </Col>
                            <Button disabled={!time} style={{ marginTop: '1rem' }} size="large" shape="round" type="primary" block onClick={nextStep}>
                                Continue
                            </Button>
                        </Row>
                    )}
                    </>
                )}
        </Content>
    );
}
