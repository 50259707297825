const USER_KEY = "loggedInUser";

export function saveUser(tokens: any) {
  localStorage.setItem(USER_KEY, JSON.stringify(tokens));
}

export function getUser() {
    if(localStorage) {
        const stored_user: any = JSON.parse(localStorage.getItem(USER_KEY) || '{}');
        if(stored_user) {
            return stored_user;
        }
    }
}

export function deleteUser() {
  localStorage.removeItem(USER_KEY);
}
