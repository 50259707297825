import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import { Button } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { convert24Hour } from '../../tools/time-converter';

import { FormModal } from '../Modals/FormModal';

const GET_BOOKING = gql`
    query ($slot_id: ID!){
        booking(slot_id: $slot_id) {
            _id
            date
            client_form_completed
            feedback_form_completed
        }
    }
`;

export const FormButton = ({type, slot}: any) => {
    let history = useHistory();
	const [formModal, setFormModal] = useState(false);
    const [formUrl, setFormUrl] = useState('');

	const toggleFormModal = () => {
        setFormModal(!formModal);
    }

    const { data: bookingData } = useQuery(GET_BOOKING, {
         variables: {slot_id: slot._id},
         skip: !slot._id
     });

    const clickHandler = () => {
        if(type === 'client') {
            if(bookingData.booking.client_form_completed) {
                const formatted_date = format(parseISO(bookingData.booking.date), 'dd-M');
                const url = `${window.env.REACT_APP_BACKEND_URL}/pdf/${slot.staff_id.first_name}-${slot.staff_id.last_name}-${convert24Hour(slot.time)}-(${formatted_date})-client-form.pdf`;
                setFormUrl(url);
                toggleFormModal();
            } else {
                history.push(`/client-form/${slot._id}`);
                window.location.reload();
            }
        }
        if(type === 'feedback') {
            if(bookingData.booking.feedback_form_completed) {
                const formatted_date = format(parseISO(bookingData.booking.date), 'dd-M');
                const url = `${window.env.REACT_APP_BACKEND_URL}/pdf/${slot.staff_id.first_name}-${slot.staff_id.last_name}-${convert24Hour(slot.time)}-(${formatted_date})-feedback-form.pdf`;
                setFormUrl(url);
                toggleFormModal();
            } else {
                history.push(`/feedback-form/${slot._id}`);
                window.location.reload();
            }
        }
    }

    return(
        <>
        {bookingData && bookingData.booking && (
            <>
                {type === 'client' && (
                    <Button type="primary" onClick={clickHandler}>
                        {bookingData.booking.client_form_completed ? (
                            <CheckCircleOutlined />
                        ) : (
                            <CloseCircleOutlined />
                        )}
                        Client Form
                    </Button>
                )}
                {type === 'feedback' && (
                    <Button type="primary" onClick={clickHandler}>
                        {bookingData.booking.feedback_form_completed ? (
                            <CheckCircleOutlined />
                        ) : (
                            <CloseCircleOutlined />
                        )}
                        Feedback Form
                    </Button>
                )}
            </>
            )}
            <FormModal isOpen={formModal} toggle={toggleFormModal} url={formUrl}/>
        </>
    );
}
