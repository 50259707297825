import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { ConfigProvider, Empty, List, Radio } from 'antd';
import { convert24Hour } from '../../../tools/time-converter';
import { CalendarOutlined, UserOutlined, } from '@ant-design/icons';

import { BookingContext } from '../../../context/BookingContext';

import { GET_BOOKING_DATA } from '../../../api/Query';

export const SlotTimes = () => {
    const { calendarId, setSlotId, setTime, setService } = useContext(BookingContext);

    const { data: bookingData } = useQuery(GET_BOOKING_DATA, {
        variables: { calendar_id: calendarId }
    });

    const changeSlotHandler = (e: any) => {
        const slot = e.target.value;
        setSlotId(slot._id);
        setTime(slot.time);
        setService(slot.service);
    }

    return (
        <>
            {bookingData && calendarId ? (
                <>
                    <h4><b><UserOutlined /> Available Treatments</b></h4>
                    <ConfigProvider renderEmpty={() => <Empty description="No Appointments Available"/>}>
                        <List dataSource={bookingData.booking_data} renderItem={(slot: any, index: number) => (
                            <Radio.Group style={{ display: 'block' }} onChange={changeSlotHandler}>
                                <List.Item key={index} className="booking-dates-item">
                                    <Radio value={slot.slots[0]}>
                                        {convert24Hour(slot._id.time)} - <i>{slot._id.service}</i> {slot.count} Available
                                    </Radio>
                              </List.Item>
                            </Radio.Group>
                        )}/>
                    </ConfigProvider>
                </>
            ) : (
                <div className="rounded-item" style={{width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                    <CalendarOutlined style={{ fontSize: '3rem', margin: '1rem 0'}}/>
                    <h3>Please Select A Date</h3>
                </div>
            )}
        </>
    );
}
