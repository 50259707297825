import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { ApolloProvider, ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { Layout } from 'antd';

import './App.css';

import { LoginPage } from './pages/LoginPage/LoginPage';
import { HomePage } from './pages/HomePage/HomePage';
import { SlotCalendarPage } from './pages/SlotCalendarPage/SlotCalendarPage';
import { TimeCalendarPage } from './pages/TimeCalendarPage/TimeCalendarPage';
import { SettingsPage } from './pages/SettingsPage/SettingsPage';
import { BookingPage } from './pages/BookingPage/BookingPage';
import { DashboardPage } from './pages/DashboardPage/DashboardPage';
import { FormPage } from './pages/FormPage/FormPage';
import { AdminPage } from './pages/AdminPage/AdminPage';

import { Sidebar } from './components/Sidebar/Sidebar';
import { ClientForm } from './components/Forms/ClientForm/ClientForm';
import { FeedbackForm } from './components/Forms/FeedbackForm/FeedbackForm';
import { CustomRoute } from './components/CustomRoute/CustomRoute';
import { getUser } from './tools/manage-tokens';
import { AuthContext } from './context/AuthContext';
import { AppContext } from './context/AppContext';

const { Content } = Layout;

declare global {
    interface Window {
        env: any;
    }
}

export const App = () => {
    const [role, setRole] = useState('');
    const [loggedIn, setLoggedIn] = useState(false);
    const [sidebarVisible, setSidebarVisible] = useState(true);
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [selectedDateString, setSelectedDateString] = useState('');
    const backend_url = `${window.env.REACT_APP_BACKEND_URL}/graphql`;
    
    const link = createHttpLink({
        uri: backend_url,
        credentials: 'include'
    });

	const client = new ApolloClient({
        cache: new InMemoryCache(),
        link: link,
	});

	useEffect(() => {
        setLoggedIn(true);
        setRole(getUser().role);
        const session_date = JSON.parse(sessionStorage.getItem('selectedDate')!);
        if(session_date) {
            setSelectedDate(new Date(session_date));
            setSelectedDateString(session_date);
        } else {
            setSelectedDate(new Date());
        }
	}, []);

	return (
		<ApolloProvider client={client}>
		  <AuthContext.Provider value={{role, setRole, loggedIn, setLoggedIn}}>
            <AppContext.Provider value={{sidebarVisible, setSidebarVisible, selectedDate, setSelectedDate, selectedDateString, setSelectedDateString}}>
                <Layout style={{ height: "100vh" }}>
                    <Router>
                        <Sidebar />
                        <Layout>
                            <Content style={{ width: '100%' }}>
                                <Switch>
                                    <CustomRoute exact path="/" component={HomePage} />
                                    <CustomRoute exact path="/login" component={LoginPage} />
                                    <CustomRoute exact path="/slot-calendar" condition="loggedIn" component={SlotCalendarPage} />
                                    <CustomRoute exact path="/time-calendar" condition="loggedIn" component={TimeCalendarPage} />
                                    <CustomRoute path="/dashboard" condition="loggedIn" component={DashboardPage} />
                                    <CustomRoute path="/form" condition="loggedIn" component={FormPage} />
                                    <CustomRoute path="/settings" condition="loggedIn" component={SettingsPage} />
                                    <CustomRoute exact path="/booking" component={BookingPage} />
                                    <CustomRoute exact path="/admin" component={AdminPage} />
                                    <CustomRoute path="/client-form/:slot_id" condition="signedIn" component={ClientForm} />
                                    <CustomRoute path="/feedback-form/:slot_id" condition="signedIn" component={FeedbackForm} />
                                </Switch>
                            </Content>
                        </Layout>
                    </Router>
                </Layout>
            </AppContext.Provider>
		  </AuthContext.Provider>
		</ApolloProvider>
	);
}

