import React, { useContext, useState } from 'react';
import { Progress, Button, Layout, PageHeader, Row, Col } from 'antd';
import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar } from 'recharts';
import { NavLink } from 'react-router-dom';
import { SyncOutlined } from '@ant-design/icons';

import { useQuery } from '@apollo/client';

import './HomePage.css';

import { GET_BOOKING_STATS, GET_SMS_USAGE, GET_SMS_SETTINGS } from '../../api/Query';

import { useAuth } from '../../hooks/useAuth';
import { AuthContext } from '../../context/AuthContext';

const { Content } = Layout;

export const HomePage = () => {
    const [loading, setLoading] = useState(true);
    const { role } = useContext(AuthContext);
    const { isLoggedIn } = useAuth();

    const { data: bookingData } = useQuery(GET_BOOKING_STATS);
    const { data: smsSettings } = useQuery(GET_SMS_SETTINGS);
    const { data: smsData } = useQuery(GET_SMS_USAGE, {
        onCompleted: () => {
            setLoading(false);
        }
    });

    return (
        <Content style={{padding: '1rem'}}>
            <PageHeader ghost={false} title='Welcome to Journal' />
            {isLoggedIn ? (
                <>
                {role === 'admin' && (
                    <>
                    {!loading ? (
                        <Row gutter={[16, 16]} style={{ marginTop: '1rem' }}>
                            <Col span={12}>
                                <div className="grid-item">
                                    <h1>Monthly Stats</h1>
                                    {bookingData &&
                                        <ResponsiveContainer width="100%" height="100%">
                                            <BarChart data={bookingData.booking_stats}>
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="month" />
                                                <YAxis />
                                                <Tooltip />
                                                <Legend />
                                                <Bar dataKey="bookings" fill="#03a9f4" />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    }
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className="grid-item">
                                    <h1>SMS Usage</h1>
                                    {smsData && smsSettings &&
                                        <>
                                            <Progress type="circle" percent={smsData.sms_usage.percent} />
                                            <h2><i>{Math.round(smsData.sms_usage.usage)} / {smsSettings.sms_settings.quota}</i></h2>
                                        </>
                                    }
                                </div>
                            </Col>
                        </Row>
                    ) : (
                        <div className="grid-item" style={{ marginTop: '1rem' }}>
                            <SyncOutlined spin style={{ fontSize: '4rem', color: '#03a9f4' }}/>
                        </div>
                    )}
                    </>
                    )}
                </>
            ) : (
                <Row gutter={[16, 16]} style={{ marginTop: '1rem' }}>
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', background: 'white', padding: '1rem', alignItems: 'center', justifyContent: 'center'}}>
                            <h1>Please Login To Access Journal</h1>
                            <NavLink to="/login">                            
                                <Button type="primary">Login</Button>
                            </NavLink>
                        </div>
                    </Col>
                </Row>
            )}
        </Content>
    );
}
