import React from 'react';
import Stripe from '../../assets/stripe-logo.png';
import Square from '../../assets/square-logo.png';
import Mastercard from '../../assets/mastercard-logo.png';
import Visa from '../../assets/visa-logo.png';

export const StripeLogo = () => {
    return (
        <a href="https://stripe.com/en-au" target="_blank" rel="noopener noreferrer">
            <img src={Stripe} height="40vh" alt="Stripe Logo" />
        </a>
    );
}

export const SquareLogo = () => {
    return (
        <a href="https://squareup.com/" target="_blank" rel="noopener noreferrer">
            <img src={Square} height="40vh" alt="Square Logo" />
        </a>
    );
}

export const MastercardLogo = () => {
    return (
        <img src={Mastercard} height="35vh" alt="Mastercard Logo" />
    );
}

export const VisaLogo = () => {
    return (
        <img src={Visa} height="35vh" alt="Visa Logo" />
    );
}

