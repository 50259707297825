import React, { useState } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { message, Layout, PageHeader, Form, Input, Button } from 'antd';

import './SettingsContainer.css';

import { PayGatewaySelect } from '../../components/Selects/PayGatewaySelect';

import { GET_SETTINGS, } from '../../api/Query';

const UPDATE_SETTINGS = gql`
mutation ($settings_id: ID!, $org_name: String, $org_logo: String, $org_map: String, $admin_email: String, $booking_email: String, $contact_number: String, $payment_gateway: String, $booking_type: String, $production: String){
        update_settings(settings_id: $settings_id, settings_input: {
            org_name: $org_name, 
            org_logo: $org_logo, 
            org_map: $org_map,
            admin_email: $admin_email,
            booking_email: $booking_email, 
            contact_number: $contact_number, 
            payment_gateway: $payment_gateway,
            booking_type: $booking_type,
            production: $production,
        }) {
            _id
		}
	}
`;


const { Content } = Layout;

export const SettingsContainer = () => {
    const { data: settingsData } = useQuery(GET_SETTINGS, { 
        onCompleted: (value: any) => {
            setBookingType(value.settings.booking_type)
            setPaymentGateway(value.settings.payment_gateway)
        }
    });
	const [updateSettings] = useMutation(UPDATE_SETTINGS);
    const [paymentGateway, setPaymentGateway] = useState('');
    const [bookingType, setBookingType] = useState('');

    const onSubmit = (form_data: any) => { 
        form_data.settings_id = settingsData.settings._id;
        form_data.payment_gateway = paymentGateway;
        form_data.booking_type = bookingType;
        updateSettings({ 
            variables: {
                ...form_data,
            },
            refetchQueries: [
                { query: GET_SETTINGS },
            ]
		})
		.then(() => {
            message.success('Settings Updated');
		});
    }

    const changePaymentGateway = (payment: string) => {
        setPaymentGateway(payment);
    }

    return (
        <Content>
            <PageHeader title="Organization Settings" style={{backgroundColor: 'white', marginBottom: '1rem'}}/>
            {settingsData && (
                <Form layout="vertical" onFinish={onSubmit} style={{ padding: '1rem', backgroundColor: 'white' }} initialValues={settingsData.settings}>
                    <Form.Item name="production" label="Production">
                        <Input disabled/>
                    </Form.Item>
                    <Form.Item name="org_code" label="Organization Code">
                        <Input disabled/>
                    </Form.Item>
                    <Form.Item name="booking_type" label="Booking Type">
                        <Input disabled/>
                    </Form.Item>
                    <Form.Item name="org_name" label="Organization Name">
                        <Input placeholder="Organization Name" />
                    </Form.Item>
                    <Form.Item name="org_logo" label="Organization Logo">
                        <Input placeholder="Organization Logo" />
                    </Form.Item>
                    <Form.Item name="org_map" label="Organization Map">
                        <Input placeholder="Organization Map" />
                    </Form.Item>
                    <Form.Item name="admin_email" label="Admin Email">
                        <Input placeholder="Admin Email" />
                    </Form.Item>
                    <Form.Item name="booking_email" label="Booking Email">
                        <Input placeholder="Booking Email" />
                    </Form.Item>
                    <Form.Item name="contact_number" label="Contact Number">
                        <Input placeholder="Contact Number" />
                    </Form.Item>
                    <Form.Item name="payment_gateway" label="Payment Gateway">
                        <PayGatewaySelect selected_pay_gateway={paymentGateway} changePayGatewayHandler={changePaymentGateway} />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">Save</Button>
                    </Form.Item>
                </Form>
            )}
        </Content>
    );
}

