import React from 'react';
import { useQuery } from '@apollo/client';
import { Select } from 'antd';
import { format } from 'date-fns';

import { GET_NEXT_CALENDARS } from '../../api/Query';

type Props = {
    selected_calendar?: string,
    changeCalendarHandler: (calendar_id: string) => void,
};

const { Option } = Select;

export const CalendarSelect = ({selected_calendar, changeCalendarHandler}: Props) => {
	const { data: calendarsData } = useQuery(GET_NEXT_CALENDARS); 

    return (
        <Select placeholder="Select a calendar" style={{ width: '100%' }} value={selected_calendar} onChange={(calendar: any) => changeCalendarHandler(calendar)}>
            {calendarsData && calendarsData.next_calendars.map((calendar: any, index: number) => {
                return (
                    <Option key={index} value={calendar._id}>{format(new Date(calendar.date), 'cccc do MMM')}</Option>
                )
            })}
        </Select>
    );
}
