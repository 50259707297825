import React, { useState } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Button, Input, Modal, message } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const GET_BOOKING = gql`
	query ($booking_id: String!){
		booking_by_id(booking_id: $booking_id) {
			_id
            booking_type
            first_name
            last_name
            mobile
            email
            service_id {
                name
                price
            }
            payment_id
            notes
		}
	}
`;

const UPDATE_NOTES = gql`
    mutation ($booking_id: String!, $notes: String!) {
        update_booking_notes(booking_id: $booking_id, notes: $notes) {
            _id
        }
    }
`;

const CANCEL_BOOKING = gql`
    mutation ($booking_id: ID!) {
        cancel_booking(booking_id: $booking_id) {
            _id
        }
    }
`;

const { TextArea } = Input;

export const ViewTimeBookingModal = ({isOpen, toggle, bookingId}: any) => {
    const [notes, setNotes] = useState('');
    const [cancelBooking] = useMutation(CANCEL_BOOKING);
    const [updateNotes] = useMutation(UPDATE_NOTES);

    const { data: bookingData } = useQuery(GET_BOOKING, {
		variables: {booking_id: bookingId},
        skip: !bookingId,
        onCompleted: (data: any) => {
            setNotes(data.booking_by_id.notes);
        }
    });

	const cancelBookingHandler = () => {
		cancelBooking({ 
			variables: {
                booking_id: bookingId,
			}
		})
		.then(() => {
            message.error('Booking Cancelled');
            window.location.reload();
        });
	}
    
    const updateNotesHandler = () => {
		updateNotes({ 
			variables: {
                booking_id: bookingId,
                notes: notes
			},
            refetchQueries: [
                { query: GET_BOOKING, variables: { booking_id: bookingId} }
            ]
        })
		.then(() => {
            message.success('Notes Updated');
        });
    }

    const setNotesHandler = (e: any) => {
        setNotes(e.target.value);
    }

    return (
        <Modal title="View Booking" visible={isOpen} onCancel={toggle} okText="Cancel Booking" cancelText="Close" onOk={cancelBookingHandler} okButtonProps={{ danger: true }} >
            {bookingData && bookingData.booking_by_id.booking_type !== 'blockout' ? (
                <>
                <div style={{ margin: '1rem 0', padding: '0.5rem', borderBottom: 'solid 0.2rem ghostwhite' }}>
                    <h3><UserOutlined /> {bookingData.booking_by_id.first_name} {bookingData.booking_by_id.last_name}</h3>
                    <h4>{bookingData.booking_by_id.mobile}</h4>
                    <h4>{bookingData.booking_by_id.email}</h4>
                    <br />
                    <h4>Treatment: {bookingData.booking_by_id.service_id.name}</h4>
                    <br />
                    {bookingData.booking_by_id.payment_id &&
                        <h4>Payment ID: {bookingData.booking_by_id.payment_id}</h4>
                    }
                </div>
                <div>
                    <h3>Notes</h3>
                    <TextArea style={{ marginBottom: '1rem' }} rows={4} defaultValue={notes} onChange={setNotesHandler} />
                    <Button type="primary" block onClick={updateNotesHandler}>Save Notes</Button>
                </div>
                </>
            ) : (
                <div>
                    <h3>Blockout Booking</h3>
                </div>
            )}
      </Modal>
    )
}
