import React, { useState } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Switch, message, Col, Form, Input, Button, Select } from 'antd';
import { CreditCardOutlined, SettingOutlined } from '@ant-design/icons';

import { GET_SQUARE_SETTINGS } from '../../api/Query';

const UPDATE_SQUARE = gql`
    mutation ($settings_id: String!, $app_id: String!, $location_id: String!, $access_token: String!, $production: Int!) {
        update_square_settings(settings_id: $settings_id, square_settings_input: {app_id: $app_id, location_id: $location_id, access_token: $access_token, production: $production}) {
            _id
        }
    }
`;

const { Option } = Select;

type Props = {
    settings: any,
    updateIntegrationHandler: (name: string, value: number) => void
};

export const SquareIntegration = ({settings, updateIntegrationHandler}: Props) => {
    const [showSettings, setShowSettings] = useState(false);
    const { data: squareSettingsData } = useQuery(GET_SQUARE_SETTINGS);
    const [updateSquare] = useMutation(UPDATE_SQUARE);

    const updateSquareHandler = (form_data: any) => {
        form_data.settings_id = squareSettingsData.square_settings._id;
        updateSquare({
            variables: {
                ...form_data
            },
            refetchQueries: [
                { query: GET_SQUARE_SETTINGS }
            ],
        }).then(() => {
            message.success('Square Settings Updated');
            setShowSettings(false);
        });
    }

    return (
        <Col flex="1" span={8}>
            <div className="integrations-item">
                {showSettings ? (
                    <>
                        {settings.square_enabled === 1 && squareSettingsData && (
                            <Form layout="vertical" onFinish={updateSquareHandler} initialValues={squareSettingsData.square_settings}>
                                <Form.Item name="app_id" label="App ID">
                                    <Input placeholder="Square App ID" />
                                </Form.Item>
                                <Form.Item name="location_id" label="Location ID">
                                    <Input placeholder="Square Location ID" />
                                </Form.Item>
                                <Form.Item name="access_token" label="Access Token">
                                    <Input placeholder="Square Access Token" />
                                </Form.Item>
                                <Form.Item name="production" label="Production">
                                    <Select>
                                        <Option value={1}>True</Option>
                                        <Option value={0}>False</Option>
                                    </Select>
                                </Form.Item>
                                <Button type="primary" block htmlType="submit">Save</Button>
                            </Form>
                        )}
                    </>
                ) : (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <CreditCardOutlined style={{ fontSize: '3rem' }}/>
                        <h1>Square</h1>
                        <Switch defaultChecked={settings.square_enabled} onChange={(value: any) => updateIntegrationHandler('square_enabled', value)}/>
                        <hr />
                        {settings.square_enabled === 1 && 
                            <SettingOutlined style={{ cursor: 'pointer', fontSize: '1rem' }} onClick={() => setShowSettings(!showSettings)}/>
                        }
                    </div>
                )}
            </div>
        </Col>
    );

}
