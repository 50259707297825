import React, { useState } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Layout, Button, PageHeader, Row, Col, Card, Tooltip, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import './TemplateContainer.css';

import { TemplateForm } from '../../components/Forms/TemplateForm/TemplateForm';
import { TemplateInfo } from '../../components/TemplateInfo/TemplateInfo';

import { GET_TEMPLATES } from '../../api/Query';

const DELETE_TEMPLATE = gql`
    mutation ($template_id: ID!) {
        delete_template(template_id: $template_id) {
            _id
        }
    }
`;

const { Content } = Layout;
const { Meta } = Card;

export const TemplateContainer = () => {
	const [createTemplate, setCreateTemplate] = useState(false);
	const { data: templateData } = useQuery(GET_TEMPLATES); 
    const [deleteTemplate] = useMutation(DELETE_TEMPLATE);

    const deleteTemplateHandler = (template_id: string) => {
		deleteTemplate({ 
			variables: {
				template_id: template_id,
			},
			refetchQueries: [
				{ query: GET_TEMPLATES },
			]
		})
		.then(() => {
            message.error('Template Deleted');
        });
    }

    return (
        <Content>
            <PageHeader title="Templates" style={{ backgroundColor: 'white', marginBottom: '1rem' }} extra={[
                <Button type="primary" key="create_template" onClick={() => setCreateTemplate(!createTemplate)}>{!createTemplate ? 'Create Template' : 'Cancel'}</Button>
            ]}/>
            {createTemplate ? (
                <TemplateForm setCreateTemplate={setCreateTemplate}/>
            ) : (
                <Row gutter={[16, 16]}>
                {templateData && templateData.templates.map((template: any, index: number) => {
                    return (
                        <Col key={index} span={6}>
                            <Card actions={[
                                <Tooltip placement="bottom" title='Delete Template'>
                                    <DeleteOutlined onClick={() => deleteTemplateHandler(template._id)} />
                                </Tooltip>
                            ]}>
                                <Meta title={template.name} />
                                <TemplateInfo info={JSON.parse(template.description)} />
                            </Card>
                        </Col>
                    );
                 })}
                </Row>
            )}
        </Content>
    );
}
