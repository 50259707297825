import React, { useState, useContext } from 'react';
import { useQuery } from '@apollo/client';
import { Button, Layout, Row, Col } from 'antd';
import { UserOutlined, PhoneOutlined, ClockCircleOutlined, CalendarOutlined, DownOutlined, SyncOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';
import { format } from 'date-fns';

import './style.css';

import { Calendar } from './components/Calendar';
import { Services } from './components/Services';
import { Times } from './components/Times';
import { ServiceString } from '../../components/Strings/ServiceString';

import { BookingContext } from '../../context/BookingContext';

import { GET_SETTINGS } from '../../api/Query';

const { Content } = Layout;

export const TimeBooking = () => {
    const { date, service, time, todayBooking, nextStep } = useContext(BookingContext);
    const { data: settingsData } = useQuery(GET_SETTINGS);
    const [showCalendar, setShowCalendar] = useState(true);
    const [showServices, setShowServices] = useState(false);
    const [showTimes, setShowTimes] = useState(false);
    const [loading, setLoading] = useState(false);
    const isMobile = useMediaQuery({query: '(max-width: 767px)'});

    const calendarHandler = () => {
        setShowCalendar(false);
        setShowServices(true);
    }

    const servicesHandler = () => {
        setShowServices(false);
        setShowTimes(true);
    }

    return (
        <Content style={{ marginTop: '1rem' }}>
            {loading ? (
                <div className="rounded-item" style={{ marginTop: '1rem', height: '400px', alignItems: 'center', justifyContent: 'center'}} >
                    <SyncOutlined style={{ fontSize: '4rem', color: '#1a90ff' }} spin/>
                </div>
            ) : (
                <>
                {isMobile ? (
                    <Row gutter={[16, 16]}> 
                        <Col span={24}>
                            <div style={{ background: 'white', padding: '1rem', borderRadius: '1rem', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                <h2><CalendarOutlined /> {format(date, 'ccc do MMM')}</h2>
                                <DownOutlined onClick={() => setShowCalendar(!showCalendar)}/>
                            </div>
                        </Col>
                        {showCalendar && (
                            <Col span={24}>
                                <div className="rounded-item">
                                    <Calendar />
                                    <hr />
                                    <Button type="primary" block size="large" disabled={!date} style={{ borderRadius: '1rem' }} onClick={calendarHandler}>
                                        Select
                                    </Button>
                                </div>
                            </Col>
                        )}
                        {todayBooking ? (
                            <Col lg={16} md={16}>
                                {settingsData && (
                                    <div className="rounded-item" style={{width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                                        <PhoneOutlined style={{ fontSize: '3rem', margin: '1rem 0'}}/>
                                        <h3>If you would like to make a booking today please call</h3>
                                        <h2>{settingsData.settings.contact_number}</h2>
                                    </div>
                                )}
                            </Col>
                        ) : (
                            <>
                            {showServices ? (
                                <Col span={24}>
                                    <div className="rounded-item">
                                        <Services />
                                        <Button type="primary" block size="large" disabled={Object.keys(service).length === 0} style={{ borderRadius: '1rem' }} onClick={servicesHandler}>
                                            Select
                                        </Button>
                                    </div>
                                </Col>
                            ) : (
                                <Col span={24}>
                                    <div style={{ background: 'white', padding: '1rem', borderRadius: '1rem', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <h2><UserOutlined /> <ServiceString service={service} /></h2>
                                        <DownOutlined onClick={() => setShowServices(!showServices)}/>
                                    </div>
                                </Col>
                            )}
                            {showTimes ? (
                                <Col span={24}>
                                    <div className="rounded-item" style={{ maxHeight: '200px' }}>
                                        <Times />
                                    </div>
                                    <Button disabled={!time} style={{ marginTop: '1rem' }} size="large" shape="round" type="primary" block onClick={nextStep}>
                                        Continue
                                    </Button>
                                </Col>
                            ) : (
                                <Col span={24}>
                                    <div style={{ background: 'white', padding: '1rem', borderRadius: '1rem', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <h2><ClockCircleOutlined />Times </h2>
                                        <DownOutlined onClick={() => setShowTimes(!showTimes)}/>
                                    </div>
                                </Col>
                            )}
                            </>
                        )}
                    </Row>
                ) : (
                    <Row gutter={[16, 16]}>
                        <Col lg={8} md={8}>
                            <div className="rounded-item">
                                <Calendar />
                            </div>
                        </Col>
                        {todayBooking ? (
                            <Col lg={16} md={16}>
                                {settingsData && (
                                    <div className="rounded-item" style={{width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                                        <PhoneOutlined style={{ fontSize: '3rem', margin: '1rem 0'}}/>
                                        <h3>If you would like to make a booking today please call</h3>
                                        <h2>{settingsData.settings.contact_number}</h2>
                                    </div>
                                )}
                            </Col>
                        ) : (
                            <>
                                <Col lg={8} md={8}>
                                    <div className="rounded-item">
                                        <Services />
                                    </div>
                                </Col>
                                <Col lg={8} md={8}>
                                    <div className="rounded-item" style={{ maxHeight: '400px' }}>
                                        <Times />
                                    </div>
                                </Col>
                                <Button disabled={!time} style={{ marginTop: '1rem' }} size="large" shape="round" type="primary" block onClick={nextStep}>
                                    Continue
                                </Button>
                            </>
                        )}
                    </Row>
                )}
                </>
            )}
        </Content>
    );
}
