import React from 'react';

type AuthContextType = {
    role: any;
    setRole: (user: any) => void;
    loggedIn: boolean;
    setLoggedIn: (loggedIn: boolean) => void;
}

export const AuthContext = React.createContext({} as AuthContextType);
