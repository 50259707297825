import React, { useContext } from 'react';
import { useQuery, gql } from '@apollo/client';
import { ConfigProvider, Empty, List, Radio } from 'antd';
import { DollarCircleOutlined, ClockCircleOutlined, UserOutlined } from '@ant-design/icons';

import { BookingContext } from '../../../context/BookingContext';

export const GET_SERVICES = gql`
    query Services ($date: String!){
        services_by_day(date: $date){
            _id
            name	
            price
            duration
		}
	}
`;

export const Services = () => {
    const { date, service, setService } = useContext(BookingContext);

    const { data: servicesData } = useQuery(GET_SERVICES, {
        variables: {
            date: date 
        },
    });

    const changeServiceHandler = (e: any) => {
        const service = e.target.value;
        setService(service);
    }

    return (
        <>
        {servicesData && date ? (
            <>
                <h4><b><UserOutlined /> Available Services</b></h4>
                <ConfigProvider renderEmpty={() => <Empty description="No Staff Available"/>}>
                    <List dataSource={servicesData.services_by_day} renderItem={(service_item: any)=> (
                        <Radio.Group size="large" onChange={changeServiceHandler} value={service}>
                            <List.Item>
                                <Radio value={service_item}>
                                    {service_item.name} <ClockCircleOutlined /> {service_item.duration} Mins <DollarCircleOutlined /> {service_item.price}
                                </Radio>
                            </List.Item>
                        </Radio.Group>
                    )}/>
                </ConfigProvider>
            </>
        ) : (
            <div className="rounded-item" style={{width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                <UserOutlined style={{ fontSize: '3rem', margin: '1rem 0'}}/>
                <h3>No Staff Available</h3>
            </div>
        )}
        </>
    );
}
