import React, { useState } from 'react';
import { Tabs, Layout } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';

import './SettingsPage.css';

import { SettingsContainer } from '../../containers/SettingsContainer/SettingsContainer';
import { CalSettingsContainer } from '../../containers/CalSettingsContainer/CalSettingsContainer';
import { IntegrationsContainer } from '../../containers/IntegrationsContainer/IntegrationsContainer';
import { StaffContainer } from '../../containers/StaffContainer/StaffContainer';
import { ServicesContainer } from '../../containers/ServicesContainer/ServicesContainer';
import { TemplateContainer } from '../../containers/TemplateContainer/TemplateContainer';
import { UserContainer } from '../../containers/UserContainer/UserContainer';
import { VoucherContainer } from '../../containers/VoucherContainer/VoucherContainer';

const { TabPane } = Tabs;
const { Content } = Layout;

export const SettingsPage = () => {
    const location = useLocation();
    const [activeKey, setActiveKey] = useState(location.pathname);
    let history = useHistory();

    const tabClickHandler = (key: any) => {
        history.push(key);
        setActiveKey(key);
    }

    return (
        <Content className="settings-page">
            <Tabs activeKey={activeKey} onTabClick={(key: any) => tabClickHandler(key)}>
                <TabPane tab="Settings" key="/settings">
                    <SettingsContainer />
                </TabPane>
                <TabPane tab="Calendar Settings" key="/settings/calsettings">
                    <CalSettingsContainer />
                </TabPane>
                <TabPane tab="Integrations" key="/settings/integrations">
                    <IntegrationsContainer />
                </TabPane>
                <TabPane tab="Staff" key="/settings/staff">
                    <StaffContainer />
                </TabPane>
                <TabPane tab="Vouchers" key="/settings/vouchers">
                    <VoucherContainer />
                </TabPane>
                <TabPane tab="Services" key="/settings/services">
                    <ServicesContainer />
                </TabPane>
                <TabPane tab="Templates" key="/settings/templates">
                    <TemplateContainer />
                </TabPane>
                <TabPane tab="Users" key="/settings/users">
                    <UserContainer />
                </TabPane>
            </Tabs>
        </Content>
    );
}

