import React, { useState, useContext } from 'react';
import { Select, TimePicker, Button, Form, Input } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { format } from 'date-fns';

import { BookingContext } from '../../../context/BookingContext';

const { RangePicker } = TimePicker;
const { Option } = Select;

export const BabyForm = () => {
    const { date, formData, setFormData } = useContext(BookingContext);
    const [formValid, setFormValid] = useState(false);

    const onSubmit = (form_data: any) => {
        console.log(form_data);
        form_data.cost = "$200";
        setFormData(form_data);
        setFormValid(true);
    }

    return (
        <>
            <h4><b><HomeOutlined /> Booking Details</b></h4>
            {!formValid ? (
                <Form name="basic" layout="vertical" onFinish={onSubmit}>
                    <Form.Item name="time" label="Time">
                        <RangePicker format="HH:mm"/>
                    </Form.Item>
                    <Form.Item name="children" label="No. Of Children">
                        <Select defaultValue="0">
                            <Option value="0">0</Option>
                            <Option value="1">1</Option>
                            <Option value="2">2</Option>
                            <Option value="3">3</Option>
                            <Option value="4">4</Option>
                            <Option value="5">5</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="gender_requirement" label="Gender Requirement">
                        <Select defaultValue="no-requirement">
                            <Option value="no-requirement">No Requirement</Option>
                            <Option value="Female Only">Female Only</Option>
                            <Option value="Male Only">Male Only</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="notes" label="Additional Notes">
                        <Input placeholder="Additional Notes"/>
                    </Form.Item>
                    <Button block type="primary" htmlType="submit">
                        Save
                    </Button>
                </Form>
            ) : (
                <>
                <h2>Booking Details</h2>
                {formData && (
                    <>
                    <h2>Date: {format(date, 'yyyy-MM-dd')}</h2>
                    <h2>Start Time: {format(formData.time[0]._d, 'HH:mm')}</h2>
                    <h2>Finish Time: {format(formData.time[1]._d, 'HH:mm')}</h2>
                    <h2>Children: {formData.children}</h2>
                    <h2>Gender Requirement: {formData.gender_requirement}</h2>
                    <h2>Notes: {formData.notes}</h2>
                    </>
                )}
                </>
            )}
        </>
    );
}
