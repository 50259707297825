import React, { useState } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Tag, Layout, Button, PageHeader, Row, Col, Card, Tooltip, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import './StaffContainer.css';

import { StaffForm } from '../../components/Forms/StaffForm/StaffForm';
import { DaySelect } from '../../components/Selects/DaySelect';
import { ServiceSelect } from '../../components/Selects/ServiceSelect';

import { GET_ALL_STAFF } from '../../api/Query';

const ADD_SERVICE = gql`
    mutation ($staff_id: ID!, $service: String!) {
        add_service(staff_id: $staff_id, service: $service) {
            _id
        }
    }
`;

const REMOVE_SERVICE = gql`
    mutation ($staff_id: ID!, $service: String!) {
        remove_service(staff_id: $staff_id, service: $service) {
            _id
        }
    }
`;

const ADD_ROSTERED_DAY = gql`
    mutation ($staff_id: ID!, $day: String!) {
        add_rostered_day(staff_id: $staff_id, day: $day) {
            _id
        }
    }
`;

const REMOVE_ROSTERED_DAY = gql`
    mutation ($staff_id: ID!, $day: String!) {
        remove_rostered_day(staff_id: $staff_id, day: $day) {
            _id
        }
    }
`;

const DELETE_STAFF = gql`
    mutation ($staff_id: ID!) {
        delete_staff(staff_id: $staff_id) {
            _id
        }
    }
`;

const { Content } = Layout;
const { Meta } = Card;

export const StaffContainer = () => {
    const [createStaff, setCreateStaff] = useState(false);
	const { data: staffData } = useQuery(GET_ALL_STAFF);
    const [deleteStaff] = useMutation(DELETE_STAFF);
    const [addRosteredDay] = useMutation(ADD_ROSTERED_DAY);
    const [removeRosteredDay] = useMutation(REMOVE_ROSTERED_DAY);
    const [addService] = useMutation(ADD_SERVICE);
    const [removeService] = useMutation(REMOVE_SERVICE);

    const deleteStaffHandler = (staff_id: string) => {
		deleteStaff({ 
			variables: {
				staff_id: staff_id,
			},
			refetchQueries: [
				{ query: GET_ALL_STAFF },
			]
		})
		.then(() => {
            message.error('Staff Deleted');
        });
    }

    const addServiceHandler = (staff_id: string, service: string) => {
		addService({ 
			variables: {
				staff_id: staff_id,
                service: service
			},
			refetchQueries: [
				{ query: GET_ALL_STAFF },
			]
		})
		.then(() => {
            message.success('Staff Service Added');
        });
    }


    const removeServiceHandler = (staff_id: string, service: string) => {
		removeService({ 
			variables: {
				staff_id: staff_id,
                service: service
			},
			refetchQueries: [
				{ query: GET_ALL_STAFF },
			]
		})
		.then(() => {
            message.error('Staff Service Removed');
        });
    }

    const addDayHandler = (staff_id: string, day: string) => {
		addRosteredDay({ 
			variables: {
				staff_id: staff_id,
                day: day
			},
			refetchQueries: [
				{ query: GET_ALL_STAFF },
			]
		})
		.then(() => {
            message.success('Staff Rostered Day Added');
        });
    }


    const removeDayHandler = (staff_id: string, day: string) => {
		removeRosteredDay({ 
			variables: {
				staff_id: staff_id,
                day: day
			},
			refetchQueries: [
				{ query: GET_ALL_STAFF },
			]
		})
		.then(() => {
            message.error('Staff Rostered Day Removed');
        });
    }

    return (
        <Content className="staff-container">
            <PageHeader title="Staff" style={{ backgroundColor: 'white', marginBottom: '1rem' }} extra={[
                <Button type="primary" key="create_staff" onClick={() => setCreateStaff(!createStaff)}>{!createStaff ? 'Create Staff' : 'Cancel'}</Button>
            ]}/>
            {createStaff ? (
                <StaffForm setCreateStaff={setCreateStaff}/>
            ) : (
                <Row gutter={[16, 16]}>
                {staffData && staffData.all_staff.map((staff: any, index: number) => {
                    return (
                        <Col key={index} span={6}>
                            <Card actions={[
                                <Tooltip placement="bottom" title='Delete Staff'>
                                    <DeleteOutlined onClick={() => deleteStaffHandler(staff._id)} />
                                </Tooltip>
                            ]}>
                                <Meta title={`${staff.first_name} ${staff.last_name}`} description={staff.email}/>
                                <hr />
                                <h3><b>Roster</b></h3>
                                <div style={{ display: 'flex', flexDirection: 'row', margin: '1rem 0' }}>
                                    {staff.roster.map((day: any) => {
                                        return (
                                            <Tag key={day} closable onClose={() => removeDayHandler(staff._id, day)}>{day}</Tag>
                                        );
                                    })}
                                </div>
                                <DaySelect changeDayHandler={(day: string) => addDayHandler(staff._id, day)} />
                                <hr />
                                <h3><b>Services</b></h3>
                                <div style={{ display: 'flex', flexDirection: 'row', margin: '1rem 0' }}>
                                    {staff.services.map((service: any) => {
                                        return (
                                            <Tag key={service._id} closable onClose={() => removeServiceHandler(staff._id, service._id)}>{service.name}</Tag>
                                        );
                                    })}
                                </div>
                                <ServiceSelect changeServiceHandler={(service: any) => addServiceHandler(staff._id, service)}/>
                            </Card>
                        </Col>
                    );
                 })}
                </Row>
            )}
        </Content>
    );
}
