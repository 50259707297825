import React, { useState } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Switch, message, Col, Form, Input, Button } from 'antd';
import { FolderOpenOutlined, SettingOutlined } from '@ant-design/icons';

import { GET_DROPBOX_SETTINGS } from '../../api/Query';

const UPDATE_DROPBOX = gql`
    mutation ($settings_id: String!, $token: String!, $path: String!) {
        update_dropbox_settings(settings_id: $settings_id, dropbox_settings_input: {token: $token, path: $path}) {
            _id
        }
    }
`;

type Props = {
    settings: any,
    updateIntegrationHandler: (name: string, value: number) => void
};

export const DropboxIntegration = ({settings, updateIntegrationHandler}: Props) => {
    const [showSettings, setShowSettings] = useState(false);
    const { data: dropboxSettingsData } = useQuery(GET_DROPBOX_SETTINGS);
    const [updateDropbox] = useMutation(UPDATE_DROPBOX);

    const updateDropboxHandler = (form_data: any) => {
        form_data.settings_id = dropboxSettingsData.dropbox_settings._id;
        updateDropbox({
            variables: {
                ...form_data
            },
            refetchQueries: [
                { query: GET_DROPBOX_SETTINGS }
            ],
        }).then(() => {
            message.success('Dropbox Settings Updated');
            setShowSettings(false);
        });
    }

    return (
        <Col flex="1" span={8}>
            <div className="integrations-item">
                {showSettings ? (
                    <>
                        {settings.dropbox_enabled === 1 && dropboxSettingsData && (
                            <Form layout="vertical" onFinish={updateDropboxHandler} initialValues={dropboxSettingsData.dropbox_settings}>
                                <Form.Item name="token" label="Token">
                                    <Input placeholder="Token" />
                                </Form.Item>
                                <Form.Item name="path" label="Folder Path">
                                    <Input placeholder="Folder Path" />
                                </Form.Item>
                                <Button type="primary" block htmlType="submit">Save</Button>
                            </Form>
                        )}
                    </>
                ) : (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <FolderOpenOutlined style={{ fontSize: '3rem' }}/>
                        <h1>Dropbox</h1>
                        <Switch defaultChecked={settings.dropbox_enabled} onChange={(value: any) => updateIntegrationHandler('dropbox_enabled', value)}/>
                        <hr />
                        {settings.dropbox_enabled === 1 && 
                            <SettingOutlined style={{ cursor: 'pointer', fontSize: '1rem' }} onClick={() => setShowSettings(!showSettings)}/>
                        }
                    </div>
                )}
            </div>
        </Col>
    );

}
